import * as actionsTypes from "./actionsTypes";

export const submitForm = (typeForm, info) => {
    return {
        type: actionsTypes.SUBMIT_FORM, 
        typeForm: typeForm,
        info: info 
    }
}

export const sucessSubmitForm = () => {
    return{
        type: actionsTypes.FORM_SUBMIT_SUCCESS,
    }
}

export const failSubmitForm = () => {
    return{
        type: actionsTypes.FORM_SUBMIT_FAIL,
    }
}

export const resetSubmit = () => {
    return{
        type: actionsTypes.RESET_SUBMIT,
    }
}

export const tryGetLinkBucket = (file) => {
    return{
        type: actionsTypes.GET_LINK_BUCKET,
        file
    }
}

export const setSuccessLinkbucket = (link_bucket) => {
    return{
        type: actionsTypes.GET_LINK_BUCKET_SUCESS,
        link_bucket
    }
}

export const setFailLinkbucket = () => {
    return{
        type: actionsTypes.GET_LINK_BUCKET_FAIL,
    }
}