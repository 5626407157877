import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import bgImage from '../../assets/images/pag404-Nearshorit_icone.svg';
import MainButton from '../../components/UI/MainButton/MainButton';
class Page404 extends Component{
    render(){
        return(  
            <section className="bg-black section-404">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-stretch">
                                <div>
                                    <h1 className="font-weight-800 green title-small margin-5px-bottom">{this.props.t('partials:page404.title-1')}</h1>
                                    <h1 className="font-weight-800 green title-small sm-margin-30px-bottom">{this.props.t('partials:page404.title-2')}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-md-between">
                        <MainButton to={this.props.t('routes:homepage.path')} btnText={this.props.t('partials:page404.btnText')} color="green" btnClasses={[ "font-weight-800"]}/>
                        <div className="col-10 col-md-6">
                            <img className="w-100 sm-margin-50px-top" src={bgImage} alt="Page 404"/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation()(Page404);