import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Head from '../../components/partials/Head/Head';
import CTA from '../../components/UI/CTA/CTA';
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlock';
import StackTech from '../../components/UI/StackTech/StackTech';
import Auxiliary from '../../hoc/Auxiliary';
import bgImage from '../../assets/images/banners/4_banner-dev-mobile.jpg';

const stack= ['IOS.png', 'objective-c.png', 'swift.png', 'android-native.png', 'android-developers-SDK.png', 'xamarin.png'];

class MobileDevelopment extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="mobileDevelopment"/>
                <Banner 
                    title = {this.props.t('mobileDevelopment:1.title')}
                    text = {this.props.t('mobileDevelopment:1.text')}
                    icon="purple-dots.svg"
                    bgImage = {bgImage}
                />
                <InfoBlock 
                    {...this.props}
                    title = {this.props.t('mobileDevelopment:2.title')}
                    infos = {this.props.i18n.getResource(this.props.i18n.language, 'mobileDevelopment')[2]['infos']}
                    transKey = "mobileDevelopment:2.infos."
                />
                <StackTech 
                    stack={stack}
                    title={this.props.t('mobileDevelopment:3.title')}
                />
                <CTA 
                    to = {this.props.t('routes:homepage.path')} 
                    btnText = {this.props.t('partials:cta.btnText')} 
                    claim = {this.props.t('mobileDevelopment:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(MobileDevelopment);