import React from 'react';
import { Container } from 'react-bootstrap';

const BannerWhy = props => {
    return (
        <section className="bg-white big-section-top-2">
            <div className="bg-purple">
                <Container className="sm-no-padding">
                    <div className="flex-container-big d-flex">
                        <div className="text-left h-100 padding-2-rem-all overflow-hidden bg-medium-gray why-content"> 
                            <div className="xs-padding-10px-bottom lg-padding-30px-tb">                                 
                                <h2 className="title-extra-small d-block font-weight-800 text-purple text-uppercase no-margin">{props.title}</h2> 
                                {props.text && <p className="text-large text-black no-margin line-height-28px padding-20px-top">{props.text}</p>}      
                            </div>               
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    )
}

export default BannerWhy;
