import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import Auxiliary from '../../../hoc/Auxiliary';
import Head from '../../../components/partials/Head/Head';
import PolicyContent from '../PolicyContent';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/cookies';

class Cookies extends Component {

    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="cookiesPolicy"/>
                <PolicyContent {...this.props} content={this.props.i18n.getResource(this.props.i18n.language, 'cookiesPolicy')} btnAction={() => this.props.onSetBannervisibility('show')}/>
            </Auxiliary>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetBannervisibility : (value) => dispatch(actions.setBannerVisibility(value))
    }
};

export default connect(null, mapDispatchToProps)(withTranslation()(Cookies));