import React, {useState} from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import element from '../../../assets/images/decoration-elements/graf-mission.svg';
import { Container, Row, Col } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import play from '../../../assets/images/icons/play.svg';

const Mission = props => {

    const [isOpen, setOpen] = useState(false);

    return (
        <section className="bg-purple mission">
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="TZ_CuFj0-Xk" onClose={() => setOpen(false)}/>
            <Container className="margin-40px-bottom">
                <Row className="align-items-center justify-content-center">
                    <Col xs={12} className="position-relative xs-no-margin md-margin-30px-bottom margin-50px-bottom xl-margin-60px-bottom">
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={1.5}>
                            <img src={props.img} alt="" />
                            <div className="absolute-left-middle-center">
                                <div className="move-to-bottom max-w">
                                    <h2 className="font-weight-600 title-large-2 green text-uppercase d-block margin-5px-bottom">{props.title}</h2>
                                    <p className="text-large text-white line-height-28px text-shadow-1 xs-no-margin">{props.text}</p>
                                </div>
                            </div>
                            <div className="absolute-far-left-center video-icon">
                                <div onClick={()=> setOpen(true)} className="popup-youtube video-icon-box position-relative cta-btn mission-video">
                                    <span className="process-step-number text-purple font-weight-800 bg-white">
                                        <img src={play} alt="" className="play-cta"/>
                                        <span className="bg-white box-shadow-large"></span>
                                    </span>
                                </div>
                                <span className="video-icon-sonar">
                                    <span className="video-icon-sonar-bfr bg-white opacity-7"></span>
                                    <span className="video-icon-sonar-afr bg-white"></span>
                                </span>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
            <img src={element} className="background-vid d-none d-md-inline-block" alt=""/>
        </section>
    )
}

export default Mission;
