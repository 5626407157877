import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Auxiliary from '../../hoc/Auxiliary';

import Head from '../../components/partials/Head/Head';
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlock';
import Steps from '../../components/UI/Steps/Steps';
import CTA from '../../components/UI/CTA/CTA';
import Benefits from '../../components/Homepage/Benefits/Benefits';
import bgImage from '../../assets/images/banners/5_banner-ux-ui.jpg';

class UXUIDesign extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="uxuiDesign"/>
                <Banner 
                    title = {this.props.t('uxuiDesign:1.title')}
                    text = {this.props.t('uxuiDesign:1.text')}
                    icon="purple-points.svg"
                    bgImage = {bgImage}
                />
                <InfoBlock 
                    {...this.props} 
                    title = {this.props.t('uxuiDesign:2.title')} 
                    infos = {this.props.i18n.getResource(this.props.i18n.language, 'uxuiDesign')[2]['infos']}
                    transKey = "uxuiDesign:2.infos."
                />
                <Steps 
                    title= {this.props.t('uxuiDesign:3.title')}  
                    steps={this.props.i18n.getResource(this.props.i18n.language, 'uxuiDesign')['3']['steps']}
                />
                <Benefits 
                    {...this.props} 
                    mainTitle = {this.props.t('uxuiDesign:4.title')}
                    boxesContent = {this.props.i18n.getResource(this.props.i18n.language, 'uxuiDesign')['4']['benefits']}
                />
                <CTA 
                    to={this.props.t('routes:homepage.path')} 
                    btnText={this.props.t('partials:cta.btnText')} 
                    claim={this.props.t('uxuiDesign:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(UXUIDesign);