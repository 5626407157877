import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Auxiliary from '../../../hoc/Auxiliary';
import { Form } from "react-bootstrap";

const CountrySelector = props => {
    const [value, setValue] = useState('')
    let countries = require('localized-countries')(require('localized-countries/data/'+ props.i18n.language+'.json')).array()

    let newCountries = countries.map((c)=>{
        c['value']=c['label']
        return c
    })

    const changeHandler = value => {
        setValue(value)
    }

    useEffect(()=>{
        if(props.clear)
            setValue("")
    }, [props.clear]) 

    return (
        <Auxiliary>
            <Form.Label className="text-extra-medium font-weight-600 d-inline-block align-top w-85 md-w-90 xs-w-85 text-purple">{props.label}</Form.Label>
            <Select options={newCountries} value={value} onChange={changeHandler} classNamePrefix="select-input" placeholder={props.t('forms:placeholders.country')} name="country"/>
        </Auxiliary>
    );
}

export default CountrySelector;