import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/partials/Head/Head';
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlock';
import CTA from '../../components/UI/CTA/CTA';
import StackTech from '../../components/UI/StackTech/StackTech';
import bgImage from '../../assets/images/banners/3_banner-dev-web.jpg';


const stack= ['java.png', 'net.png', 'outsystems.png', 'php.png', 'python.png', 'go.png', 'node.png', 'react.png', 'angular.png', 'html-css.png','javascript.png'];
class WebDevelopment extends Component{
    render(){
        return(
            <Auxiliary>
                    <Head {...this.props} id="webDevelopment"/>
                    <Banner
                        title = {this.props.t('webDevelopment:1.title')}
                        text = {this.props.t('webDevelopment:1.text')}
                        icon="green-squares.svg"
                        bgImage = {bgImage}
                    />
                    <InfoBlock 
                        {...this.props}
                        title = {this.props.t('webDevelopment:2.title')}
                        infos = {this.props.i18n.getResource(this.props.i18n.language, 'webDevelopment')[2]['infos']}
                        transKey = "webDevelopment:2.infos."
                    />
                    <StackTech 
                        title={this.props.t('webDevelopment:3.title')}
                        stack={stack}
                    />
                    <CTA 
                        to = {this.props.t('routes:homepage.path')} 
                        btnText = {this.props.t('partials:cta.btnText')} 
                        claim = {this.props.t('webDevelopment:cta.claim')}
                    />
            </Auxiliary>
        )
    }
}

export default withTranslation()(WebDevelopment);