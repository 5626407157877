import i18n from 'i18next';
import languageDetector from "i18next-browser-languagedetector";
import languageDetectorOptions from "./languageDetectorOptions";
import { initReactI18next } from 'react-i18next';

//imports of translation files
import routesEN from './assets/locales/en/routes.json';
import routesPT from './assets/locales/pt/routes.json';
import routesDE from './assets/locales/de/routes.json';
import partialsEN from './assets/locales/en/partials.json';
import partialsPT from './assets/locales/pt/partials.json';
import partialsDE from './assets/locales/de/partials.json';
import metatagsEN from './assets/locales/en/metatags.json';
import metatagsPT from './assets/locales/pt/metatags.json';
import metatagsDE from './assets/locales/de/metatags.json';
import homepageEN from './assets/locales/en/homepage.json';
import homepagePT from './assets/locales/pt/homepage.json';
import homepageDE from './assets/locales/de/homepage.json';
import itConsultingEN from './assets/locales/en/itConsulting.json';
import itConsultingPT from './assets/locales/pt/itConsulting.json';
import itConsultingDE from './assets/locales/de/itConsulting.json';
import softwareDevelopmentEN from './assets/locales/en/softwareDevelopment.json';
import softwareDevelopmentPT from './assets/locales/pt/softwareDevelopment.json';
import softwareDevelopmentDE from './assets/locales/de/softwareDevelopment.json';
import webDevelopmentEN from './assets/locales/en/webDevelopment.json';
import webDevelopmentPT from './assets/locales/pt/webDevelopment.json';
import webDevelopmentDE from './assets/locales/de/webDevelopment.json';
import mobileDevelopmentEN from './assets/locales/en/mobileDevelopment.json';
import mobileDevelopmentPT from './assets/locales/pt/mobileDevelopment.json';
import mobileDevelopmentDE from './assets/locales/de/mobileDevelopment.json';
import uxuiDesignEN from './assets/locales/en/uxuiDesign.json';
import uxuiDesignPT from './assets/locales/pt/uxuiDesign.json';
import uxuiDesignDE from './assets/locales/de/uxuiDesign.json';
import softwareTestingEN from './assets/locales/en/softwareTesting.json';
import softwareTestingPT from './assets/locales/pt/softwareTesting.json';
import softwareTestingDE from './assets/locales/de/softwareTesting.json';
import cloudEN from './assets/locales/en/cloud.json';
import cloudPT from './assets/locales/pt/cloud.json';
import cloudDE from './assets/locales/de/cloud.json';
import dataScienceEN from './assets/locales/en/dataScience.json';
import dataSciencePT from './assets/locales/pt/dataScience.json';
import dataScienceDE from './assets/locales/de/dataScience.json';
import businessIntelligenceEN from './assets/locales/en/businessIntelligence.json';
import businessIntelligencePT from './assets/locales/pt/businessIntelligence.json';
import businessIntelligenceDE from './assets/locales/de/businessIntelligence.json';
import machineLearningEN from './assets/locales/en/machineLearning.json';
import machineLearningPT from './assets/locales/pt/machineLearning.json';
import machineLearningDE from './assets/locales/de/machineLearning.json';
import bigDataEN from './assets/locales/en/bigData.json';
import bigDataPT from './assets/locales/pt/bigData.json';
import bigDataDE from './assets/locales/de/bigData.json';
import whyPortugalEN from './assets/locales/en/whyPortugal.json';
import whyPortugalPT from './assets/locales/pt/whyPortugal.json';
import whyPortugalDE from './assets/locales/de/whyPortugal.json';
import whyNearshoritEN from './assets/locales/en/whyNearshorit.json';
import whyNearshoritPT from './assets/locales/pt/whyNearshorit.json';
import whyNearshoritDE from './assets/locales/de/whyNearshorit.json';
import faqsEN from './assets/locales/en/faqs.json';
import faqsPT from './assets/locales/pt/faqs.json';
import faqsDE from './assets/locales/de/faqs.json';
import jobsEN from './assets/locales/en/jobs.json';
import jobsPT from './assets/locales/pt/jobs.json';
import jobsDE from './assets/locales/de/jobs.json';
import formsEN from './assets/locales/en/forms.json';
import formsPT from './assets/locales/pt/forms.json';
import formsDE from './assets/locales/de/forms.json';
import modalsEN from './assets/locales/en/modals.json';
import modalsPT from './assets/locales/pt/modals.json';
import modalsDE from './assets/locales/de/modals.json';
import privacyPolicyEN from './assets/locales/en/privacyPolicy.json';
import privacyPolicyPT from './assets/locales/pt/privacyPolicy.json';
import privacyPolicyDE from './assets/locales/de/privacyPolicy.json';
import cookiesPolicyEN from './assets/locales/en/cookiesPolicy.json';
import cookiesPolicyPT from './assets/locales/pt/cookiesPolicy.json';
import cookiesPolicyDE from './assets/locales/de/cookiesPolicy.json';

//associate the translations with locales
const resources = {
    en: {
        routes: routesEN,
        partials: partialsEN,
        homepage: homepageEN,
        itConsulting: itConsultingEN,
        softwareDevelopment: softwareDevelopmentEN,
        webDevelopment: webDevelopmentEN,
        mobileDevelopment: mobileDevelopmentEN,
        softwareTesting: softwareTestingEN,
        uxuiDesign: uxuiDesignEN,
        cloud:cloudEN,
        dataScience:dataScienceEN,
        businessIntelligence:businessIntelligenceEN,
        machineLearning:machineLearningEN,
        bigData:bigDataEN,
        whyPortugal:whyPortugalEN,
        whyNearshorit:whyNearshoritEN,
        faqs:faqsEN,
        forms: formsEN,
        jobs: jobsEN,
        modals:modalsEN,
        privacyPolicy:privacyPolicyEN,
        cookiesPolicy:cookiesPolicyEN,
        metatags:metatagsEN,
    },
    pt:{
        routes: routesPT,
        partials: partialsPT,
        homepage: homepagePT,
        itConsulting: itConsultingPT,
        softwareDevelopment: softwareDevelopmentPT,
        webDevelopment: webDevelopmentPT,
        mobileDevelopment: mobileDevelopmentPT,
        uxuiDesign: uxuiDesignPT,
        softwareTesting: softwareTestingPT,
        cloud: cloudPT,
        dataScience:dataSciencePT,
        businessIntelligence:businessIntelligencePT,
        machineLearning:machineLearningPT,
        bigData:bigDataPT,
        whyPortugal:whyPortugalPT,
        whyNearshorit:whyNearshoritPT,
        faqs:faqsPT,
        forms: formsPT,
        jobs: jobsPT,
        modals:modalsPT,
        privacyPolicy:privacyPolicyPT,
        cookiesPolicy:cookiesPolicyPT,
        metatags:metatagsPT,
    },
    de:{
        routes: routesDE,
        partials: partialsDE,
        homepage: homepageDE,
        itConsulting: itConsultingDE,
        softwareDevelopment: softwareDevelopmentDE,
        webDevelopment: webDevelopmentDE,
        mobileDevelopment: mobileDevelopmentDE,
        uxuiDesign: uxuiDesignDE,
        softwareTesting: softwareTestingDE,
        cloud: cloudDE,
        dataScience:dataScienceDE,
        businessIntelligence:businessIntelligenceDE,
        machineLearning:machineLearningDE,
        bigData:bigDataDE,
        whyPortugal:whyPortugalDE,
        whyNearshorit:whyNearshoritDE,
        faqs:faqsDE,
        forms: formsDE,
        jobs: jobsDE,
        modals:modalsDE,
        privacyPolicy:privacyPolicyDE,
        cookiesPolicy:cookiesPolicyDE,
        metatags:metatagsDE,
    }
}


i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: "en",
    detection: languageDetectorOptions,
    fallbackLng: ["pt","en","de"],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong' , 'i','a'] // don't convert to <1></1> if simple react elements
  });


export default i18n;