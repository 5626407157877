import React from 'react';
import {Helmet} from "react-helmet";

const Head = props => {
    const linkServer = 'http://nearshorit.com';
    const {i18n, id} = props;
    
    const title = 'metatags:' + id + '.title' ;
    const description = 'metatags:' + id + '.description';
    const currentLink = navigator.userAgent !== 'ReactSnap' ? window.location.href : (linkServer + window.location.pathname); 

    //shared image
    const imageName = id + '.image';
    //folder where to look for the share
    const langFolder = i18n.language === "pt" ? "pt" : "en";
    const img = i18n.exists('metatags:' + id + '.image', langFolder) ? require("../../../assets/images/shared/" + langFolder + i18n.getResource(langFolder, 'metatags', imageName)) : null;

    //const img = null;
    let imgLink = null; 
    if (img)
        imgLink = navigator.userAgent !== 'ReactSnap' ? window.location.origin + img.default :  linkServer + img.default ; 

    return(
        <Helmet titleTemplate="%s | NearshorIT" defaultTitle="NearshorIT">
            <html lang={i18n.language} amp />
            <meta name="title" property="og:title" content=""/>
            <title>{i18n.t(title)}</title>
            {id !== "cookiesPolicy" && id !== "privacyPolicy" && (<meta name="description" content={i18n.t(description)}/>) }
            {id !== "cookiesPolicy" && id !== "privacyPolicy" && (<meta name="og:description" content={i18n.t(description)}/>)}
            <meta property="og:url" content={currentLink}/>
            <meta property="og:type" content="WebPage"/>
            <meta property="og:site_name" content="NearshorIT"/>
            {img ? (<meta name="image" property="og:image" content={imgLink}/>): null}
            <meta name="image" property="og:image" content="image"/>
            <meta name="google-site-verification" content="wFb5YKxP_ftTr-t7ZoRJmJNm2qkmR3y9YsYW-nFovLQ" />
       </Helmet>
    )
}

export default Head;