import React from 'react';
import { Container } from 'react-bootstrap';
import sideElement from '../../../assets/images/decoration-elements/graf-banner-why.svg';
import sideElementRevert from '../../../assets/images/decoration-elements/graf-banner-why-revert.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const Section3 = props => {
    return (
        <section className="bg-medium-light-gray no-section">
            <Container className="flex-container md-no-padding-lr">
                <div><img src={sideElement} alt=""/></div>
                    <div className="whyBox">
                    <Link to={props.t('routes:whyNearshorIT.path')} className="feature-box-hover text-center">
                        <div className="d-flex align-items-center justify-content-between justify-content-lg-center md-padding-2-rem-all padding-3-rem-all h-100">
                            <h3 className="text-extra-large d-block font-weight-800 text-purple no-margin-bottom text-uppercase margin-40px-right">
                                {props.t('homepage:2-1.1')}
                            </h3> 
                            <div>
                                {/* <span className="process-step-icon-bfr bg-white box-shadow-medium"></span> */}
                                <span className="process-step-number text-purple font-weight-800 bg-white">?</span>
                            </div>
                        </div>
                    </Link>
                </div>
                {props.windowWidth > 500 ? 
                    <div><img src={sideElementRevert} alt=""/></div>  :  <div><img src={sideElement} alt=""/></div>}
                <div className="whyBox">
                    <Link to={props.t('routes:whyPortugal.path')} className="feature-box-hover text-center">
                        <div className="d-flex align-items-center justify-content-between justify-content-lg-center md-padding-2-rem-all padding-3-rem-all h-100">
                            <h3 className="text-extra-large d-block font-weight-800 text-purple no-margin-bottom text-uppercase margin-40px-right">
                                {props.t('homepage:2-1.2')}
                            </h3> 
                            <div>
                                {/* <span className="process-step-icon-bfr bg-white box-shadow-medium"></span> */}
                                <span className="process-step-number text-purple font-weight-800 bg-white">?</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div><img src={sideElement} alt=""/></div>
            </Container>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(Section3);
