import React from 'react';
import TitleCenter from '../TitleCenter/TitleCenter';
import StackTechItem from './StackTechItem';
import { Container } from 'react-bootstrap';

const StackTech = props => {
    let currentStack = props.stack; 
    let text = props.text;
    let i = 1;
    let background = props.background ? props.background : "bg-white";

    let stackDisplay = currentStack.map((key) => {
        let display = text ? <StackTechItem key={key} image={key} text={text[i]} background={background}/> :  <StackTechItem  key={key} image={key} background={background}/>
        i = i+1
        return display;
    })
    return (
        <section className={["stack-section","small-section", "Half-Height-Background", "sm-no-padding-top", props.background ? props.background : "bg-white"].join(" ")}>
            <TitleCenter color="green" title={props.title}/>
            <Container>
                <div className={["row", "margin-40px-tb", "sm-margin-20px-top", "sm-no-margin-bottom",currentStack.length % 3 === 0 ? null: 'add-element-bottom' ].join(" ")}>
                    {stackDisplay}
                </div>
            </Container>
        </section>
    )
}

export default StackTech;
