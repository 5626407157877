import React from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import BenefitInfo from '../../UI/BenefitInfo/BenefitInfo';
import TitleCenter from '../../UI/TitleCenter/TitleCenter';
import rightElement from '../../../assets/images/decoration-elements/element-benefits.svg';
import { Trans } from 'react-i18next';

const Benefits = props => {

    let benefitBoxes = props.boxesContent;
    let keys = Object.keys(benefitBoxes);
    let left = keys.length % 3;
    let leftTablet = keys.length % 2;
    const transKey = props.transKey ? props.transKey : null;
    
    let displayBenefits= keys.map(key => {
        return(
            <BenefitInfo 
                key={key}
                title={transKey ? 
                    <Trans 
                        t={props.t} 
                        i18nKey={props.transKey + key +".title"}  
                        components={{italic: <i></i> }}
                    /> : benefitBoxes[key]['title']} 
                text={transKey ? 
                    <Trans 
                        t={props.t} 
                        i18nKey={props.transKey + key +".text"}  
                        components={{
                            italic: <i></i>,
                            linkStudy2: <a href="https://www.visionofhumanity.org/maps/" target="_new" className="dark-purple"/>
                        }}
                    /> : benefitBoxes[key]['text']
                }
                img={benefitBoxes[key]['img']}
            />

        )
    })
    
    return (
        <section className="bg-black extra-small-section">
            <TitleCenter title={props.mainTitle} color="purple"/>
                <Container>
                    <Row className="row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-start">
                        {displayBenefits}
                        {left !== 0 && props.windowWidth > 992 && keys.length %2 === 0 && <div className="d-flex justify-content-end align-items-end">
                            
                        </div>}
                        {left !== 0 && props.windowWidth > 992 && <div className="d-flex justify-content-end align-items-end">
                            <img src={rightElement} alt="" className="md-w-180px margin-15px-right"/>
                        </div>}
                        {leftTablet !== 0 && props.windowWidth < 992 && props.windowWidth >= 768 && keys.length %2 !== 0 && <div className="d-flex justify-content-end align-items-end">
                            <img src={rightElement} alt="" className="md-w-180px  margin-15px-right"/>
                        </div>}
                    </Row>
                </Container>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(Benefits);
