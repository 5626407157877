import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container} from 'react-bootstrap';
import { connect } from 'react-redux';

const TitleCenter = props => {
    let color = props.color;

    return (
        <div className={["row", "title-center", color].join(" ")}>
            <div className="col-12 text-md-center">
                <Container>
                    <ScrollAnimation animateIn="flipInX" animateOnce={true} duration={1.5}>
                        <h2 className={["font-weight-600","d-md-inline-block", "title-large-2", "md-no-margin-bottom", (color === "purple"? "green": "text-purple"), props.classes].join(" ")}>
                            {props.title}
                        </h2>
                    </ScrollAnimation>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(TitleCenter);
