import {React, useState, useRef, useEffect} from 'react';
import { Col, Row, Container, Form } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/forms';

import { elementsToJson } from '../../../shared/functions';
import Input from "../Input/Input";
import FormModal from "../../partials/FormModal/FormModal";
import MainButton from '../../UI/MainButton/MainButton';
import {maxChar} from '../../../shared/functions';

const JobsForm = props => {

    //controll checkbox state
    const [checked, setChecked] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [openedOnce, setOpenedOnce] = useState(false);
    const refBtnSubmitJobs = useRef();

    const [ inputValues , setInputValues ] = useState(null);
    const [ cvTextBtn , setCvTextBtn ] = useState(props.t('forms:labels.cvBtn'));
    const [ cvError , setCvError ] = useState(null);

    const refForm = useRef();
    const refInputCv = useRef();
    const refBtnCv = useRef();
    const formRef = useRef(null);
    let hash = props.location.hash;

    const {link_bucket, submited} = props;

    //modal content
    const [ modalContent , setModalContent  ] = useState({
        title: null,
        textKey: null,
        btn: null,
        checkbox: null,
    });

    //set cvBtn label 
    useEffect(() => {
        if(!submited && !refInputCv.current.files[0]){
            setCvTextBtn(props.t('forms:labels.cvBtn'))
        }
    }, [props.i18n.language]);

    useEffect(() => {
        if(hash === "#form" && formRef.current.offsetTop){
            setTimeout(() => {
                window.scrollTo({
                    left:0, 
                    top:formRef.current.offsetTop,
                    behavior:"smooth"
                });
            }, 500);
            props.history.replace(props.location.pathname);
        }
    });

    //check if the CV uploaded is valid
    const checkCv = () => {
        //without uploaded file
        if (!refInputCv.current.files[0]){
            setCvTextBtn(props.t('forms:labels.cvBtn'));
            refInputCv.current.value = null;
            setCvError(true);
            return;
        }
        //tests file validity
        if(!/pdf|png|jpg|jpeg/.test(refInputCv.current.files[0].type) || refInputCv.current.files[0].size > 5242880){
            refInputCv.current.value = "";
            setCvError(1);
            setCvTextBtn(props.t('forms:labels.cvBtn'));
        }else{
            setCvTextBtn( maxChar( refInputCv.current.files[0].name , 15 ));
            setCvError(false);
        }
        refBtnCv.current.classList.remove('loading-cv');  
    }

    const clickCv = (e) => {
        e.preventDefault();
        setCvError(null);
        // if ( isIE() ){
        //     refBtnCv.current.classList.add('loading');
        // }else{
            setTimeout(
                () => refBtnCv.current.classList.add('loading-cv'),
                500
            );
       // }
        refInputCv.current.click();
    }

    //check form vallidity
    const checkForm = (event) => {
        //check the form validity
        event.preventDefault();
        if(event.target.form.checkValidity()){
            //cehck if terms are accepted
            if(checked){
                //save elements 
                const inputValues = elementsToJson(event.target.form.elements);
                setInputValues(inputValues);
                
                //upload file to bucket
                if ( event.target.form.elements && event.target.form.elements.cv.files.length ){
                    const file = event.target.form.elements.cv.files[0];
                    props.ontryGetLinkbucket(file);
                }
                //submit form when link_bucket changes (useEffect)
                
            }
            else{
                if(!openedOnce){
                    setModalContent({
                        title: props.t('modals:jobs.termsMissing.title') ,
                        textKey: 'modals:jobs.termsMissing.text',
                        btn: {
                            label: props.t('modals:jobs.termsMissing.btn'),
                            onClick : (e) => refBtnSubmitJobs.current.click()
                        },
                        checkbox: {
                            link: props.t('routes:privacyPolicy.path'),
                            label: props.t('modals:jobs.termsMissing.terms')
                        }
                    })
                    setModalOpen(true);
                    setOpenedOnce(true);
                }
                else{
                    //open modal saying it didn't send the contact
                    setModalContent({
                        title: props.t('modals:jobs.errorTermsMissing.title') ,
                        textKey: 'modals:jobs.errorTermsMissing.text',
                    })
                    setModalOpen(true);
                }
            }
        } else{
            let scroll = true;
            const _elements = Object.entries(event.target.form.elements);
            _elements.forEach((obj) =>{
                if (!isNaN(parseInt(obj[0]))){
                    //create event
                    const event = new Event('input',{
                        bubbles: true,
                        cancelable: true,
                    });
                    //dispatch event to the input with error
                    obj[1].dispatchEvent(event);
                    if ( scroll && obj[0] !== "0" ){
                        scroll = false;
                        obj[1].scrollIntoView({
                            behavior: 'smooth', 
                            block: 'nearest', 
                            inline: 'nearest' 
                        });
                    }
                }
            })
            checkCv();
        }
    }

    //submit form after checking inputs and terms
    const submitForm = (inputs) => {
        if(inputs){
            //bot control
            if(inputs.honey && inputs.honey !==''){
                setModalContent({ 
                    title: props.t('modals:errorSend.title'), 
                    textKey: 'modals:errorSend.text'
                });
                setModalOpen(true);
                props.onResetSubmit();
                return;
            }
            //disable btn and add loading
            inputs.cv = link_bucket;
            inputs['idOffer'] = "";

            //remove inputs not needed
           
            refBtnSubmitJobs.current.disabled =  true;
            refBtnSubmitJobs.current.classList.add('loading');

            //send email
            props.onTrySubmitForm('offer',inputs);
        }
    }

    //when is setted a new bucket_link, send email 
    useEffect(() => {
        if(link_bucket){            
           submitForm(inputValues);
        }
        else if(link_bucket === false){
            setModalContent({
                title: props.t('modals:jobs.errorSend.title'),
                textKey: 'modals:jobs.errorSend.text',
            });
            setModalOpen(true); 
            refBtnSubmitJobs.current.disabled =  false;
            refBtnSubmitJobs.current.classList.remove('loading');
            props.onResetSubmit();
            
        }
    }, [link_bucket]);

    //result after submit form with CV
    useEffect(() => { 
        if(submited){
            setModalContent({
                title: props.t('modals:jobs.sucessSend.title'),
                textKey: 'modals:jobs.sucessSend.text',
            })
            setModalOpen(true);   
            refBtnSubmitJobs.current.classList.remove('loading');
            refBtnSubmitJobs.current.disabled =  false;
            props.onResetSubmit();
            
            //clear inputs
            refForm.current.reset();
            setChecked(false);
            refInputCv.current.value = null;
            setCvTextBtn(props.t('forms:labels.cvBtn'));
         
        }else if( submited === false ) {
            setModalContent({
                title: props.t('modals:jobs.errorSend.title'),
                textKey: 'modals:jobs.errorSend.text',
            })
            setModalOpen(true);   
            refBtnSubmitJobs.current.disabled =  false;
            refBtnSubmitJobs.current.classList.remove('loading');    
            props.onResetSubmit();
        }
    }, [submited]);


    return (
        <section className="section position-relative sm-padding-40px-tb" ref={formRef}>
            <FormModal 
                {...props}
                id='jobsModal' 
                title={modalContent.title} 
                textKey={modalContent.textKey} 
                btn={modalContent.btn}  
                checkbox = {modalContent.checkbox}  
                checked={checked}  
                modalOpen = {modalOpen}
                onChange={(e)=> setChecked(e.target.checked)} 
                closed={() => setModalOpen(false)}
            />
            <Container>
                <Row>
                    <div className="margin-4-rem-bottom sm-margin-25px-bottom">
                        <ScrollAnimation animateIn="flipInX" animateOnce={true} duration={1.5}>
                            <Col>
                                <span className="font-weight-600 d-md-inline-block text-gradient-nearshorit title-large-2">{props.t('jobs:3.title')}</span>
                            </Col>
                        </ScrollAnimation>
                    </div>
                </Row>
                <Form autoComplete="off" className="costumForm" id="form1" ref={refForm}>
                    <input type="text" name="honey" id="honey" className="d-none" autoComplete="off" />
                    <Row className="row-cols-1 row-cols-md-2">
                        <Col className="sm-margin-15px-bottom">
                            <Form.Group className="mb-3">
                                <Input 
                                    type="text" 
                                    placeholder={props.t('forms:placeholders.name')} 
                                    name="name" 
                                    minLength={3} 
                                    label={props.t('forms:labels.name')}
                                    errormsg={props.t('forms:messages.errorMinLength')} 
                                    requiredmsg={props.t('forms:messages.errorRequired')} 
                                    required
                                />
                                <Input 
                                    type="email" 
                                    placeholder={props.t('forms:placeholders.email')} 
                                    name="email" 
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
                                    label={props.t('forms:labels.email')} 
                                    errormsg={props.t('forms:messages.errorEmail')}  
                                    requiredmsg={props.t('forms:messages.errorRequired')} 
                                    required
                                />
                                <Input 
                                    type="text"
                                    name="phone" 
                                    placeholder={props.t('forms:placeholders.phone')}
                                    minLength={9} 
                                    label={props.t('forms:labels.phone')} 
                                    pattern="^[+\d](?:.*\d)?$"
                                    errormsg={props.t('forms:messages.errorPhone')} 
                                    requiredmsg={props.t('forms:messages.errorRequired')} 
                                />
                                <Input 
                                    type="text" 
                                    placeholder="Linkedin"  
                                    name="linkedin" 
                                    minLength={3} 
                                    label= "Linkedin"  
                                    errormsg={props.t('forms:messages.errorMinLength')} 
                                />                      
                            </Form.Group>
                        </Col>
                        <Col className="sm-no-margin-bottom">
                            <Form.Group>
                                <Input 
                                    _ref={refInputCv}
                                    type="file" 
                                    name="cv" 
                                    label= {props.t('forms:labels.cv')}   
                                    errormsg={props.t('forms:messages.errorUploadCv')} 
                                    onChange={(e) => checkCv(e)} 
                                    required
                                />
                                <MainButton 
                                    _ref={refBtnCv} 
                                    id="btnCv" 
                                    click={(e) => clickCv(e) } 
                                    color="pink"
                                    btnClasses={["text-white","fw-600", "w-100",  "text-extra-medium", ( cvError ? 'error' : '' ) ]}
                                    space="margin-40px-bottom"
                                    size="btn-medium"
                                    btnText={cvTextBtn}
                                >
                                    {cvTextBtn}
                                </MainButton>
                                {cvError && <p className="errorMsg">{cvError === 1 ? props.t('forms:messages.errorUploadCv')  : props.t('forms:messages.errorRequired')}</p>}
                                <Input 
                                    type="text" 
                                    placeholder={props.t('forms:labels.reach-us')}
                                    name="to-us" 
                                    minLength={3} 
                                    label= {props.t('forms:labels.reach-us')}   
                                    errormsg={props.t('forms:messages.errorMinLength')} 
                                />
                                <Input 
                                    type="textarea"
                                    name="message" 
                                    placeholder={props.t('forms:placeholders.message')} 
                                    minLength={10} 
                                    label={props.t('forms:labels.message')}  
                                    errormsg={props.t('forms:messages.errorMinLength')}  
                                    requiredmsg={props.t('forms:messages.errorRequired')} 
                                    required
                                />
                                
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row >
                        <Col className="d-flex flex-column align-items-end justify-content-end" >
                            <Input 
                                type="checkbox"
                                name="terms" 
                                id="terms"
                                checked = {checked}
                                label={props.t('forms:labels.personalTerms')} 
                                link={props.t('routes:privacyPolicy.path')} 
                                onChange={(e)=> setChecked(e.target.checked)} 
                            />
                            <MainButton 
                                _ref={refBtnSubmitJobs} 
                                btnText={props.t('forms:labels.btn')} 
                                click={(e) => checkForm(e)} 
                                color="green" 
                                space="margin-1-rem-top"
                            />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </section>
    )
}


const mapStateProps = state => {
    return {
        link_bucket: state.forms.link_bucket,
        submited: state.forms.submited
    }
  }
  
const mapDispatchProps = dispatch => {
    return {
       ontryGetLinkbucket: (file) => dispatch( actions.tryGetLinkBucket(file)),
       onTrySubmitForm: (typeForm,form) => dispatch( actions.submitForm(typeForm,form) ),
       onResetSubmit: () => dispatch( actions.resetSubmit() )
    }
}


export default connect(mapStateProps,mapDispatchProps)(withTranslation()(JobsForm));
