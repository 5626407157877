import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/functions';

const initialState = {
    link_bucket: null,
    submited: null,
}

const reducer = ( state = initialState, action ) => {
    switch( action.type ){
        case actionTypes.FORM_SUBMIT_SUCCESS: 
            return updateObject( state, { submited: true } );
        case actionTypes.FORM_SUBMIT_FAIL: 
            return updateObject( state, { submited: false } );
        case actionTypes.RESET_SUBMIT: 
            return updateObject( state, { link_bucket: null, submited: null } );
        case actionTypes.GET_LINK_BUCKET_SUCESS: 
            return updateObject( state, { link_bucket: action.link_bucket } );
        case actionTypes.GET_LINK_BUCKET_FAIL: 
            return updateObject( state, { link_bucket: false } );
        default:
            return state;           
    }
}

export default reducer;