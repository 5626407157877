import {React, useState} from "react";
import { Form } from "react-bootstrap";
import Auxiliary from "../../../hoc/Auxiliary";
import { Link } from 'react-router-dom'

const Input = props => {

    const {id, label , type , required , name , minLength , pattern , placeholder , errormsg , requiredmsg, onChange, checked, link, _ref} = props;

    const [error, setError] = useState(null);
   
    //verifies if the input is correctly filled and change error state
    const checkInputValidity = (event) => {
        const check = event.target.checkValidity();
        if (check){
            event.target.classList.remove("error");
            setError(false);
        }else{
            event.target.classList.add("error");
            const inputError = event.target.value === '' ? "required" : "other"; 
            setError(inputError);
        }
    }

    let on_input = checkInputValidity;
  
    const setInput = () => {
       switch(type){
            case "text":
                return ( 
                    <Form.Control type="text" placeholder={placeholder} name={name} minLength={minLength} errormsg={errormsg} requiredmsg={requiredmsg} required={required} onInput={on_input} className="medium-input bg-white margin-25px-bottom"/>
                )
            case "email":
                return (
                    <Form.Control type="email" placeholder={placeholder} name={name} pattern={pattern} errormsg={errormsg} requiredmsg={requiredmsg} required={required} onInput={on_input} className="medium-input bg-white margin-25px-bottom"/>
                )
            case "textarea":
                return (
                    <Form.Control as="textarea" placeholder={placeholder} rows={7} type="textarea" name={name} minLength={minLength} errormsg={errormsg} requiredmsg={requiredmsg} required={required} onInput={on_input} className="medium-textarea bg-white h-160px"/>
                )
            case "checkbox":
                let linkTo = (<Link to={link} target="new">{label}</Link>);
                return (
                    // <Form.Check type="checkbox" label={linkTo} id={id} name={name} onChange={onChange} className="mt-3" checked={checked}/>
                    <div className="control-group">
                        <label className="control control-checkbox">
                            {linkTo}
                                <input type="checkbox" checked={checked} id={id} name={name} onChange={onChange} className="mt-3"/>
                            <div className="control_indicator"></div>
                        </label>
                    </div>
                )
            case "file":
                return (
                    <input ref={_ref} id={id} type="file" name={name} errorMsg={errormsg} onChange={onChange} accept=".jpg, .jpeg, .png,.pdf" required className="d-none"/>
                )

               
            default:
                return null;
       }
    }

    //return the type of input according to props
    let input = setInput();

    return(
        <Auxiliary>
            {label && type !== "checkbox"? <Form.Label className="text-extra-medium font-weight-600 d-inline-block align-top w-85 md-w-90 xs-w-85 text-purple">{label}{required && '*'}</Form.Label> : null}
            {input}
            {error && (errormsg || requiredmsg) && <p className="errorMsg">{error === "required" ? requiredmsg : errormsg.replace(' : ', ` ${minLength} `)}</p>}
        </Auxiliary>
    )
}

export default Input;