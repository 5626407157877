import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/partials/Head/Head';
import BannerWhy from '../../components/UI/BannerWhy/BannerWhy';
import ImagesGallery from '../../components/UI/ImagesGallery/ImagesGallery';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlock';
import CTA from '../../components/UI/CTA/CTA';
import img1 from '../../assets/images/gallery/whyPortugal/1_portugal-image.jpg';
import img2 from '../../assets/images/gallery/whyPortugal/2_portugal-image.jpg';
import img3 from '../../assets/images/gallery/whyPortugal/5_portugal-image.jpg';
import img4 from '../../assets/images/gallery/whyPortugal/3_portugal-image.jpg';
import { isIE } from '../../shared/functions';
import { connect } from 'react-redux';


const images = [
    {
        img: img1,
        title: '',
        cols: 4,
        rows: 4,
    },
    {
        img: img2,
        title: '',
        cols: 4,
        rows: 2,
    },    
    {
        img: img3,
        title: '',
        rows: 2,
        cols: 2,
    },
    {
        img: img4,
        title: '',
        cols: 2,
        rows: 2,
    }
];

const imagesMobile = [
    {
        img: img1,
        title: '',
        cols: 4,
        rows: 4,
    },
    {
        img: img2,
        title: '',
        cols: 4,
        rows: 2,
    },    
    {
        img: img3,
        title: '',
        rows: 2,
        cols: 2,
    },
    {
        img: img4,
        title: '',
        cols: 2,
        rows: 2,
    }
];
class WhyPortugal extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="whyPortugal"/>
                <BannerWhy 
                    title={this.props.t('whyPortugal:1.title')} 
                    text={this.props.t('whyPortugal:1.text')}
                />
                {!isIE() && this.props.windowWidth < 576 ? <ImagesGallery {...this.props}  images ={imagesMobile} className="bg-purple"/> :<ImagesGallery {...this.props} images ={images} className="margin-100px-bottom md-no-margin-tb"/>}
                <InfoBlock
                    {...this.props}
                    title={this.props.t('whyPortugal:2.title')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'whyPortugal')['2']['advantages']}
                    transKey="whyPortugal:2.advantages."
                />
                <CTA 
                    to = {this.props.t('routes:homepage.path')} 
                    btnText = {this.props.t('partials:cta.btnText')} 
                    claim = {this.props.t('whyPortugal:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}


export default connect(mapStateToProps)(withTranslation()(WhyPortugal));