import React from 'react'
import { Container, Row } from 'react-bootstrap';
import IconTitleHor from '../../UI/IconTitleHor/IconTitleHor';

//get all images from a directory
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

  
const Services = props =>{

    let services = props.services;
    let servicesKeys = Object.keys(services);
    let images = importAll(require.context('../../../assets/images/homepage/services', false, /\.(svg)$/));
    
    let displayServices = servicesKeys.map(key => {
        return (
            <IconTitleHor 
                key={key} 
                title={services[key]['title']} 
                icon={images[services[key]['img']].default}
                to = {props.t('routes:'+key+'.path')}
            />
        )
    })

    return (
        <section className="extra-small-section bg-light-green no-padding-top">
            <div className="bg-purple sm-margin-25px-bottom md-margin-40px-bottom margin-80px-bottom h-90px xs-h-100px align-items-center d-flex justify-content-md-center md-padding-15px-lr">
                <h2 className="green text-uppercase title-small font-weight-700 line-height-60-px no-margin">
                    {props.title}
                </h2>
            </div>
                <Container>
                    <Row className="row-cols-1 row-cols-lg-2 row-cols-md-2 half-margin">
                       {displayServices}
                    </Row>
                </Container>
        </section>
    )
}

export default Services
