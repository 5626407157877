import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Benefits from '../../components/Homepage/Benefits/Benefits';
import Head from '../../components/partials/Head/Head';
import CTA from '../../components/UI/CTA/CTA';
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import Auxiliary from '../../hoc/Auxiliary';
import StackTech from '../../components/UI/StackTech/StackTech';
import Box from '../../components/UI/Box/Box';
import bgImage from '../../assets/images/banners/9_banner-busi-intelligence.jpg';

const stack= ['power-bi.png', 'tableau.png', 'qlik.png', 'qlik-sense.png', 'microstrategy.png','sas.png', 'ssis.png', 'ssrs.png'];
class BusinessIntelligence extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="businessIntelligence"/>
                <Banner 
                    title={this.props.t('businessIntelligence:1.title')}
                    icon="green-horizontal-bars.svg"
                    bgImage = {bgImage}
                />
                <Box
                    {...this.props}
                    title={this.props.t('businessIntelligence:2.title')}
                    text={this.props.t('businessIntelligence:2.text')}
                    transKey="businessIntelligence:2."
                />
                <Benefits 
                    mainTitle ={this.props.t('businessIntelligence:3.title')}
                    boxesContent = {this.props.i18n.getResource(this.props.i18n.language, 'businessIntelligence')['3']['benefits']}
                    color = "green"
                />
                <StackTech 
                    title={this.props.t('businessIntelligence:4.title')}
                    stack={stack}
                />
                <CTA 
                    to = {this.props.t('routes:homepage.path')} 
                    btnText = {this.props.t('partials:cta.btnText')} 
                    claim = {this.props.t('businessIntelligence:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(BusinessIntelligence);