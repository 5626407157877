import React, { useState, useEffect } from 'react';
import CookieConsent from "react-cookie-consent"; 

import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';

import { connect } from 'react-redux';
import * as actions from '../../../store/actions/cookies';

//Check cookies value
import { Cookies, getCookieConsentValue} from "react-cookie-consent";

//Google Tag Manager e Google Analytics
import * as ReactGA from "react-ga";
import TagManager from 'react-gtm-module';

const CookiesBanner = props => {

    const [cookies, setcookies] = useState(props.cookies);
    const [showBanner, setshowbanner] = useState(props.banner_visibility);
    
    let initG = false; //! prod change to false

    //set GA and GTM
    const setGA = () =>{
        if( props.cookies){
            ReactGA.initialize('UA-72968223-11'); 
            const tagManagerArgs = {
                gtmId: 'GTM-TNKCS74'
            }
            TagManager.initialize(tagManagerArgs);
            initG = true;
        }
        if(props.cookies != cookies){
            setcookies(props.cookies)
        }
    }

    //listen for cookie value changes
    useEffect(() => {
        setGA();
    }, [props.cookies]) 

    //control banner visibility
    useEffect(() => {
        setshowbanner(props.banner_visibility)
    }, [props.banner_visibility]) 

    //init cookies value
    useEffect(() => {
        if(getCookieConsentValue("cookiesAccepted") !== props.cookies && getCookieConsentValue("cookiesAccepted")){
            if(getCookieConsentValue("cookiesAccepted")=='true')
                props.onAcceptCookies()
            else
                props.onRemoveCookies()
        }
    }, []) 

    const acceptAllCookies = () =>{
        props.onAcceptCookies();
        props.onSetBannervisibility("byCookieValue")
    }

    //remove cookies
    const removeAllCookies = () =>{
        props.onRemoveCookies();
        props.onSetBannervisibility("byCookieValue");

        let hostname = document.location.hostname.replace('www.','');
        //remove google cookies
        Cookies.remove("_ga", { path: '/', domain: hostname });
        Cookies.remove("_gat", { path: '/', domain: hostname });
        Cookies.remove("_gat_UA-72968223-11", { path: '/', domain: hostname });
        Cookies.remove("_gid", { path: '/', domain: hostname });
        
        //reload to remove script
        setTimeout(
            () => window.location.reload(),
            1000
        ); 
    }

    return(
        <CookieConsent
            location="bottom"
            cookieName="cookiesAccepted"
            visible={showBanner}
            onAccept={() => {
                acceptAllCookies();
            }}
            onDecline={() => {
                removeAllCookies();
            }}
            buttonText={props.t('partials:cookies-banner.btn3')}
            enableDeclineButton
            declineButtonText = {props.t('partials:cookies-banner.btn2')}
            declineButtonStyle = {{color: "#fff", fontSize: "12px",  backgroundColor: "transparent", textDecoration:"underline", marginLeft:0, marginBottom:0, paddingLeft:0,  paddingBottom:0 }}
            buttonStyle={{ color: "#000", fontSize: "16px", fontWeight:700, backgroundColor:"#00FFBB", marginTop:"10px", marginLeft:0}}
            style={{ background: "#000" }}
            buttonWrapperClasses="button-group"
            contentClasses="cookies-content"
            expires={150}>
                <h2 className="text-large margin-10px-bottom">
                    <Trans 
                        t={props.t} 
                        i18nKey="partials:cookies-banner.title"  
                        components={{green: <span className="green"></span> }}
                    />
                </h2>
                <p className="text-small"> 
                    <Trans 
                        t={props.t} 
                        i18nKey="partials:cookies-banner.text"  
                        components={{linkCookies: <Link className="white" to={props.t('routes:cookiesPolicy.path')}></Link> }}
                    />
                </p>
        </CookieConsent>    
    )
}

const mapStateToProps = state => {
    return {
        cookies : state.cookies.accepted_cookies,
        banner_visibility : state.cookies.banner_visibility
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onAcceptCookies : () => dispatch(actions.acceptCookies()),
        onRemoveCookies : () => dispatch(actions.removeCookies()),
        onSetBannervisibility : (value) => dispatch(actions.setBannerVisibility(value))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CookiesBanner);