import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

//components
import Head from '../../components/partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import CTA from '../../components/UI/CTA/CTA';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlock';
import Benefits from '../../components/Homepage/Benefits/Benefits';
import bgImage from '../../assets/images/banners/7_banner-cloud.jpg';

class Cloud extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="cloud"/>
                <Banner 
                    title = {this.props.t('cloud:1.title')}
                    icon="purple-horizontal-bars.svg"
                    bgImage = {bgImage}
                />
                <Benefits 
                    {...this.props} 
                    mainTitle = {this.props.t('cloud:2.title')}
                    boxesContent = {this.props.i18n.getResource(this.props.i18n.language, 'cloud')['2']['services']}
                    colorTitle = "green"
                />
                <InfoBlock 
                    {...this.props} 
                    title = {this.props.t('cloud:3.title')}
                    infos = {this.props.i18n.getResource(this.props.i18n.language, 'cloud')['3']["infos"]}
                    transKey = "cloud:3.infos."
                />
                <Benefits 
                    {...this.props} 
                    mainTitle = {this.props.t('cloud:4.title')}
                    boxesContent = {this.props.i18n.getResource(this.props.i18n.language, 'cloud')['4']['benefits']}
                    colorTitle = "green"
                    transKey = "cloud:4.benefits."
                />
                <CTA 
                    to = {this.props.t('routes:homepage.path')} 
                    btnText = {this.props.t('partials:cta.btnText')} 
                    claim = {this.props.t('cloud:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Cloud);