import {React, useState, useRef, useEffect} from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import element1 from '../../../assets/images/homepage/map/map-element-1.svg';
import element1Pink from '../../../assets/images/homepage/map/map-element-1-pink.svg';
import element2 from '../../../assets/images/homepage/map/map-element-2.svg';
import element2Pink from '../../../assets/images/homepage/map/map-element-2-pink.svg';
import logo from '../../../assets/images/logos/nearshortit.svg';


const Map = props => {
    const [showHeadOffice, setShowHeadOffice] = useState(false)
    const [showLisbonOffice, setlisbonOffice] = useState(true)
    const [srcElement1, setSrcElement1] = useState(null)
    const [srcElement2, setSrcElement2] = useState(null)
    
    const contactRef = useRef(null);
    let hash = props.location.hash;

    useEffect(() => {
        showLisbonOffice ? setSrcElement1(element1Pink) : setSrcElement1(element1)
        showHeadOffice ? setSrcElement2(element2Pink) : setSrcElement2(element2) 
    }, [showLisbonOffice, showHeadOffice])

    const elem1 = useRef(null)
    const elem2 = useRef(null)

    const changeMap = (office) => {
        if(office === "headOffice"){
            setShowHeadOffice(true)
            setlisbonOffice(false)
        }else{
            setShowHeadOffice(false)
            setlisbonOffice(true)
        }
    }
    const changeImg = (key) => {
        if(key === 1){
            elem1.current.src = element1Pink
        } else if(key === 2){
            elem2.current.src = element2Pink
        }
    }
    const changeImgOut = (key) => {
        if(key === 1 && !showLisbonOffice){
            elem1.current.src = element1
        } else if(key === 2 && !showHeadOffice){
            elem2.current.src = element2
        }
    }
    //scroll to this section when click on contacts footer
    useEffect(() => {
        if(hash === "#contacts"){
            setTimeout(() => {
                window.scrollTo({
                    left:0, 
                    top:contactRef.current.offsetTop - 60 ,
                    behavior:"smooth"
                });
            }, 500);
            props.history.replace(props.location.pathname);
        }
    });

    return (
        <section className="bg-black half-section" ref={contactRef}>
            <Container>
                <Row className="row-cols-1 row-cols-lg-1 row-cols-md-1">
                    <Col lg={5}>
                        <img src={logo} alt="NearshorIT logo"/>
                        <div className="d-flex md-flex-row flex-column md-margin-20px-bottom margin-40px-bottom margin-20px-top contacts">
                            <a href="tel:+351938591825" className="green text-extra-medium margin-two-right d-inline-block font-weight-700">(+351) 938 591 825 </a>
                            <a href="tel:+351213304403" className="green text-extra-medium margin-two-right d-inline-block font-weight-700">(+351) 213 304 403</a>
                            <a href="mailto:letswork@nearshorit.com" className="text-extra-medium margin-four-right green d-block font-weight-700">letswork@nearshorit.com</a>
                        </div>
                        <div onClick={() =>changeMap('lisbonOffice')} onMouseOver={() => changeImg(1)}  onMouseOut={() => changeImgOut(1)} className={["d-flex","align-items-center","margin-20px-bottom", "address-block", (showLisbonOffice && "address-block-selected")].join(" ")}>
                            <img src={srcElement1} alt="" className="margin-10px-right sm-w-50px" ref={elem1}/>
                            <div><span className="text-uppercase green font-weight-700">{props.t('homepage:8.office-1')} </span><span className="text-white font-weight-700">Praça Duque de Saldanha, n° 20, 1° Dto. 1050-094 Lisboa, Portugal </span></div>
                        </div>
                        <div onClick={()=>changeMap('headOffice')} onMouseOver={() => changeImg(2)}  onMouseOut={() => changeImgOut(2)} className={["d-flex", "align-items-center", "address-block", (showHeadOffice && "address-block-selected")].join(" ")}> 
                            <img src={srcElement2} alt="" className="margin-10px-right sm-w-50px" ref={elem2}/>
                            <div><span className="text-uppercase green font-weight-700">{props.t('homepage:8.office-2')} </span><span className="text-white font-weight-700">3CE - Centro de Conhecimento, Competências e Empresas - Rua 25 de Abril, nº 313, 4520-115, Aveiro, Portugal</span></div>
                        </div>
                    </Col>
                    <Col lg={7}>
                    {showLisbonOffice && 
                        <div className="col xs-margin-15px-bottom md-margin-30px-top">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.2791369996767!2d-9.146377084654414!3d38.7343562795961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1933a022a6fc15%3A0x852a2a4b0ac9b54e!2sPra%C3%A7a%20Duque%20de%20Saldanha%2020%2C%201000-247%20Lisboa!5e0!3m2!1spt-PT!2spt!4v1629994231095!5m2!1spt-PT!2spt" width="100%" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy" title="Lisbon Office location"></iframe>
                        </div>
                    }
                    {showHeadOffice && 
                        <div className="col xs-margin-15px-bottom md-margin-30px-top">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3014.8601740541176!2d-8.574665484283113!3d40.91881163287362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2386d4d18e8fb7%3A0x49014bbb42d1a039!2s3CE%20-%20Centro%20de%20Neg%C3%B3cios!5e0!3m2!1spt-PT!2spt!4v1632823067138!5m2!1spt-PT!2spt" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" title="Head Office location"></iframe>
                        </div>
                    }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Map
