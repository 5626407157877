import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { Container } from 'react-bootstrap';
import {Trans} from 'react-i18next';
import InfoBlockIcon from '../InfoBlockIcon/InfoBlockIcon';
import Auxiliary from '../../../hoc/Auxiliary';

const Culture = props => {

    let content = props.content;
    let contentKeys = Object.keys(content);
    const transKey = props.transKey ? props.transKey: null;

    let displayInfo = contentKeys.map((key) => {
        return (
            <InfoBlockIcon 
                title={transKey ? 
                    <Trans 
                        t={props.t} 
                        i18nKey={props.transKey + key +".title"}  
                        components={{italic: <i></i> }}
                    /> : content[key]['title']} 
                text={transKey ? 
                    <Trans 
                        t={props.t} 
                        i18nKey={props.transKey + key +".text"}  
                        components={{italic: <i></i> }}
                    /> : content[key]['text']
                }
                icon={content[key]['icon']}
            />
        )
    })
    return (
        <Auxiliary>
            <section className="half-section bg-purple background-top-bottom">
                <Container>
                    <div className="row">
                        <div className="col-12">
                            <ScrollAnimation animateIn="flipInX" animateOnce={true} duration={1.5}>
                                <h2 className=" font-weight-600 green title-small sm-margin-20px-bottom md-margin-40px-bottom margin-60px-bottom">{props.title}</h2>
                            </ScrollAnimation>
                        </div>
                    </div>
                    {displayInfo}
                    {/* <div>
                        <img src={bottomElement} className="d-none d-md-inline-block float-right background-bottom" alt=""/>
                    </div> */}
                </Container>
            </section>
        </Auxiliary>
    )
}

export default Culture
