import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Head from '../../components/partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import CTA from '../../components/UI/CTA/CTA';
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlock';
import Steps from '../../components/UI/Steps/Steps';
import bgImage from '../../assets/images/banners/6_banner-software-testes.jpg';

class SoftwareTesting extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="softwareTesting"/>
                <Banner 
                    title = {this.props.t('softwareTesting:1.title')}
                    icon="green-solid.svg"
                    bgImage = {bgImage}
                />
                <InfoBlock
                    {...this.props}
                    title = {this.props.t('softwareTesting:2.title')} 
                    infos = {this.props.i18n.getResource(this.props.i18n.language, 'softwareTesting')[2]['infos']}
                    transKey="softwareTesting:2.infos."
                />
                <Steps 
                    title= {this.props.t('softwareTesting:3.title')}  
                    steps={this.props.i18n.getResource(this.props.i18n.language, 'softwareTesting')['3']['steps']}
                />
                <CTA 
                    to={this.props.t('routes:homepage.path')} 
                    btnText={this.props.t('partials:cta.btnText')} 
                    claim={this.props.t('softwareTesting:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(SoftwareTesting);