import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Head from '../../components/partials/Head/Head';
import Culture from '../../components/UI/Culture/Culture';
import JobsForm from '../../components/UI/JobsForm/JobsForm';
import StackTech from '../../components/UI/StackTech/StackTech';
import Future from '../../components/Jobs/Future/Future';
import Auxiliary from '../../hoc/Auxiliary';

import futureImage from '../../assets/images/work-it.png';
const stack= ['1-horarios.svg', '2-formacao.svg', '3-certificacoes.svg', '4-progressao.svg', '5-remoto.svg', '6-saude.svg','6-parceiros.svg','7-internacional.svg',];
class Jobs extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="jobs"/>
                <Future 
                    img={futureImage} 
                    title={this.props.t('jobs:1.title')} 
                    text={this.props.t('jobs:1.text')}
                />
                <StackTech 
                    stack={stack} 
                    title={this.props.t('jobs:2.title')}
                    text={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['2']['topics']}
                    background="bg-light-green"
                />
                <JobsForm {...this.props}/>
                <Culture 
                    title={this.props.t('jobs:4.title')} 
                    content={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['4']['topics']}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Jobs);