import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/partials/Head/Head';
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import CTA from '../../components/UI/CTA/CTA';
import Steps from '../../components/UI/Steps/Steps';
import Services from '../../components/UI/Services/Services';
import bgImage from '../../assets/images/banners/1_banner-consultoria.jpg';
class ITConsulting extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="itConsulting"/>
                <Banner
                    title = {this.props.t('itConsulting:1.title')}
                    text = {this.props.t('itConsulting:1.text')}
                    icon="green-dots.svg"
                    bgImage = {bgImage}
                />
                <Services 
                    {...this.props} 
                    services={this.props.i18n.getResource(this.props.i18n.language, 'itConsulting')['2']['services']}  
                    title={this.props.t('itConsulting:2.title')}
                />
                <Steps 
                    title= {this.props.t('itConsulting:3.title')}  
                    steps={this.props.i18n.getResource(this.props.i18n.language, 'itConsulting')['3']['steps']}
                />
                <CTA 
                    to={this.props.t('routes:homepage.path')} 
                    btnText={this.props.t('partials:cta.btnText')} 
                    claim={this.props.t('itConsulting:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(ITConsulting);