//To support IE
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


import React from 'react';
import { Provider } from 'react-redux';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
//import thunk from 'redux-thunk';
import { watchForm } from './store/sagas/index';

import './index.css';
import App from './App';
import { hydrate, render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

//REDUCERS
import windowReducer from './store/reducers/window';
import formsReducer from './store/reducers/forms';
import cookiesReducer from './store/reducers/cookies';

//REDUX
const composeEnhancers = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : (null || compose);

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    window: windowReducer,
    forms: formsReducer,
    cookies:cookiesReducer
});

const store = createStore ( 
    rootReducer, 
    composeEnhancers(
        applyMiddleware( sagaMiddleware )
    )
   // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

sagaMiddleware.run( watchForm );

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    rootElement
  );
}else{
    render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    rootElement
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
