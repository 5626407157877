import React from 'react';
import TitleCenter from '../TitleCenter/TitleCenter';
import Block from '../InfoBlock/Block';
import {Container} from 'react-bootstrap';
import {Trans} from 'react-i18next';

const Box = props => {
    return (
        <section className="sm-no-padding small-section bg-medium-gray xs-no-padding-top">
            <TitleCenter title={props.title} color="green"/>
            <Container>
                <Block
                    text= {<Trans 
                        t={props.t} 
                        i18nKey={props.transKey +"text"}  
                        components={{italic: <i></i> }}
                    />}
                />
            </Container>
        </section>
    )
}

export default Box;
