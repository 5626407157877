import React from 'react';
import { Link } from 'react-router-dom';

const MainButton = props => {

    let btnClasses = ["btn", props.size ? props.size : 'btn-extra-large', props.color === "green" ? "btn-green" : (props.color === "pink" ? "bg-pink": "btn-white"), props.space ? props.space : 'no-margin', props.btnClasses? props.btnClasses.join(" "): null];
    
    let btn =  props.to ? 
        (props.hash ? 
            <Link to={{pathname:props.to, hash:props.hash}}  className={btnClasses.join(" ")}>
                {props.btnText}
                <span className="bg-medium-slate-blue"></span>
            </Link> : 
            <Link to={props.to}  className={btnClasses.join(" ")}>
                {props.btnText}
                <span className="bg-medium-slate-blue"></span>
            </Link>
        ): (<button className={btnClasses.join(" ")} onClick={props.click} ref={props._ref} >
                {props.btnText}
                <span className="bg-medium-slate-blue"></span>
            </button>);

    return (
        <div>{btn}</div>
    )
}

export default MainButton;
