import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/partials/Head/Head';
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlock';
import Benefits from '../../components/Homepage/Benefits/Benefits';
import Steps from '../../components/UI/Steps/Steps';
import CTA from '../../components/UI/CTA/CTA';
import bgImage from '../../assets/images/banners/8_banner-data-cience.jpg';

class DataScience extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="dataScience"/>
                <Banner 
                    title = {this.props.t('dataScience:1.title')}
                    icon="green-points.svg"
                    bgImage = {bgImage}
                />
                 <InfoBlock 
                    {...this.props} 
                    title = {this.props.t('dataScience:2.title')}
                    infos = {this.props.i18n.getResource(this.props.i18n.language, 'dataScience')['2']["infos"]}
                />
                <Benefits 
                    {...this.props} 
                    mainTitle = {this.props.t('dataScience:3.title')}
                    boxesContent = {this.props.i18n.getResource(this.props.i18n.language, 'dataScience')['3']['benefits']}
                    colorTitle = "green"
                />
                <Steps 
                    title= {this.props.t('dataScience:4.title')}  
                    steps={this.props.i18n.getResource(this.props.i18n.language, 'dataScience')['4']['steps']}
                />
                <CTA 
                    to = {this.props.t('routes:homepage.path')} 
                    btnText = {this.props.t('partials:cta.btnText')} 
                    claim = {this.props.t('dataScience:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(DataScience);