import React from 'react'
import Auxiliary from '../../../hoc/Auxiliary';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';

const MenuItem = props =>{
    return (
        <Auxiliary>
            {props.windowWidth <= 767 ? 
                <NavLink exact to={props.route} onClick={props.click} className="text-white">
                    <li className="menu-list-item">
                        <NavLink exact to={props.route} onClick={props.click} className="text-white">{props.text}</NavLink>
                        <span className="menu-toggle">
                            <svg width="58" height="91" viewBox="0 0 58 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.07 90.0056C7.74436 90.0057 6.43321 89.7299 5.22 89.1956C3.67712 88.5559 2.35811 87.4739 1.42917 86.0858C0.50024 84.6978 0.00295963 83.0658 0 81.3956L0 8.58557C0.00886398 6.92047 0.508901 5.29501 1.43746 3.91284C2.36603 2.53067 3.68182 1.45325 5.22 0.815567C6.88124 0.0735257 8.72244 -0.169359 10.5193 0.116502C12.3161 0.402364 13.991 1.20463 15.34 2.42557L55.07 38.8256C55.9373 39.6004 56.6313 40.5496 57.1064 41.6111C57.5815 42.6727 57.8271 43.8226 57.8271 44.9856C57.8271 46.1486 57.5815 47.2985 57.1064 48.36C56.6313 49.4215 55.9373 50.3708 55.07 51.1456L15.34 87.5556C13.6308 89.1291 11.3932 90.0034 9.07 90.0056ZM6.66 59.0956V81.3956C6.67812 81.7654 6.80563 82.1216 7.02638 82.4189C7.24714 82.7162 7.55119 82.9413 7.9 83.0656C8.37706 83.2847 8.90719 83.3616 9.42686 83.2871C9.94653 83.2126 10.4337 82.9899 10.83 82.6456L50.56 46.2456C50.7442 46.0945 50.8926 45.9045 50.9944 45.6893C51.0963 45.474 51.1492 45.2387 51.1492 45.0006C51.1492 44.7624 51.0963 44.5272 50.9944 44.3119C50.8926 44.0966 50.7442 43.9066 50.56 43.7556L10.84 7.33557C10.4437 6.99125 9.95653 6.7685 9.43686 6.69401C8.91719 6.61952 8.38706 6.69644 7.91 6.91557C7.55932 7.03827 7.25314 7.26264 7.03051 7.56008C6.80788 7.85751 6.67889 8.21452 6.66 8.58557V59.0956Z" fill="#00FFC2"/>
                            </svg>
                        </span>
                    </li>   
                </NavLink> : 
                <li className="menu-list-item">
                  <NavLink exact to={props.route} onClick={props.click} className="text-white">{props.text}</NavLink>
                  <span className="menu-toggle">
                      <NavLink to={props.route} onClick={props.click} className="no-padding">
                          <svg width="58" height="91" viewBox="0 0 58 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.07 90.0056C7.74436 90.0057 6.43321 89.7299 5.22 89.1956C3.67712 88.5559 2.35811 87.4739 1.42917 86.0858C0.50024 84.6978 0.00295963 83.0658 0 81.3956L0 8.58557C0.00886398 6.92047 0.508901 5.29501 1.43746 3.91284C2.36603 2.53067 3.68182 1.45325 5.22 0.815567C6.88124 0.0735257 8.72244 -0.169359 10.5193 0.116502C12.3161 0.402364 13.991 1.20463 15.34 2.42557L55.07 38.8256C55.9373 39.6004 56.6313 40.5496 57.1064 41.6111C57.5815 42.6727 57.8271 43.8226 57.8271 44.9856C57.8271 46.1486 57.5815 47.2985 57.1064 48.36C56.6313 49.4215 55.9373 50.3708 55.07 51.1456L15.34 87.5556C13.6308 89.1291 11.3932 90.0034 9.07 90.0056ZM6.66 59.0956V81.3956C6.67812 81.7654 6.80563 82.1216 7.02638 82.4189C7.24714 82.7162 7.55119 82.9413 7.9 83.0656C8.37706 83.2847 8.90719 83.3616 9.42686 83.2871C9.94653 83.2126 10.4337 82.9899 10.83 82.6456L50.56 46.2456C50.7442 46.0945 50.8926 45.9045 50.9944 45.6893C51.0963 45.474 51.1492 45.2387 51.1492 45.0006C51.1492 44.7624 51.0963 44.5272 50.9944 44.3119C50.8926 44.0966 50.7442 43.9066 50.56 43.7556L10.84 7.33557C10.4437 6.99125 9.95653 6.7685 9.43686 6.69401C8.91719 6.61952 8.38706 6.69644 7.91 6.91557C7.55932 7.03827 7.25314 7.26264 7.03051 7.56008C6.80788 7.85751 6.67889 8.21452 6.66 8.58557V59.0956Z" fill="#00FFC2"/>
                          </svg>
                      </NavLink>
                  </span>
              </li>}
        </Auxiliary>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(MenuItem);
