import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import Auxiliary from '../../../hoc/Auxiliary';


const Step = props => {

    return (
        <Auxiliary>
            {props.windowWidth > 767 && <Col xs={12} sm={8} className="text-center process-step-style-04 sm-margin-6-rem-bottom">
                <div className="process-step-item">
                    <span className="process-step-item-bfr bg-white"></span>
                    <div className="process-step-item-box">
                    {!props.last && <span className="process-step-item-box-bfr bg-green opacity-2 d-none d-md-block"></span>}
                        <div className="process-step-icon">
                            <span className="process-step-icon-bfr bg-white"></span>
                            <span className="process-step-number text-purple font-weight-500">
                                <span className="process-step-number-bfr bg-white"></span>
                                {props.step}
                            </span>
                        </div>
                        <div className="process-content last-paragraph-no-margin">
                            <h3 className="text-extra-medium d-block font-weight-500 text-white margin-10px-bottom text-uppercase green">{props.title}</h3>
                            {props.text && <p className="text-white  margin-10px-bottom">{props.text}</p>}
                        </div>                        
                    </div>
                </div>
            </Col>}
            {props.windowWidth < 768 && 
            <Container>
                <Col className={["d-flex","align-items-start", "step-content-mobile", props.last && "last"].join(" ")}>
                    <div className="w-40px h-40px bg-white step-number font-weight-700">
                        <span className="text-purple">{props.step}</span>
                    </div>
                    <div className="text-content">
                        <h3 className="text-extra-medium d-block font-weight-500 text-white margin-10px-bottom text-uppercase green no-margin-bottom margin-40px-left">{props.title}</h3>
                        {props.text && <p className="text-white margin-10px-bottom sm-margin-40px-left">{props.text}</p>}
                    </div>
                </Col>
            </Container>
            }
        </Auxiliary>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(Step);
