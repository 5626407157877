import {React} from 'react';
import Auxiliary from '../../../hoc/Auxiliary';
import AccordionItem from './AccordionItem';

const CustomAccordion = props => {

    let faqs = props.faqs;
    let faqsKeys = Object.keys(faqs);

    let displayFaqs = faqsKeys.map(key =>{
        return(
            <AccordionItem 
                title= {faqs[key]['title']}
                text= {faqs[key]['text']}
                key = {key}
            />
        )
    })

  return (
        <Auxiliary>
            {displayFaqs}
        </Auxiliary>
  );
}
    
export default CustomAccordion;
