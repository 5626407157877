import React from 'react'
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const IconTitleHor= props => {

    let content = (
        <div className="feature-box h-100 feature-box-left-icon-middle padding-3-rem-all bg-white box-sahdow-iconTitleHor box-shadow-large-hover border-radius-4px overflow-hidden last-paragraph-no-margin lg-padding-2-half-rem-tb lg-padding-2-rem-lr md-padding-2-rem-tb">
                <div className="feature-box-icon margin-20px-right lg-margin-25px-right">
                    <img src={props.icon} alt="" className="md-w-50px"/>
                </div>
                <div className="feature-box-content">
                    <h3 className="font-weight-700 text-extra-large margin-5px-bottom text-purple text-uppercase">{props.title}</h3>
                </div>
            </div>
        )

    return (
        <Col sm={12} className="margin-two-bottom">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={2} className="h-100">
                {props.to[0] === "/" ? <Link to={props.to}> {content} </Link>: content}
            </ScrollAnimation>
        </Col>
    )
}

export default IconTitleHor;
