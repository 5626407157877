import React from 'react';
import { Col, Row } from 'react-bootstrap';

import icon from '../../../assets/images/book.png';

const InfoBlockIcon = props => {
    let iconImage = props.icon ? require("../../../assets/images/icons/" + props.icon).default : icon;
    return (
        <Row className="row-cols-1 row-cols-lg-1 row-cols-md-1 half-margin" id="infoBlockIcon">
            <Col className="margin-two-bottom">
                    <div className="verticalLine feature-box h-100 feature-box-left-icon-middle padding-3-rem-all bg-white box-shadow-small box-shadow-large-hover border-radius-4px overflow-hidden last-paragraph-no-margin lg-padding-2-half-rem-tb lg-padding-2-rem-lr md-padding-25px-all sm-padding-15px-all">
                        <div className="feature-box-icon margin-30px-right lg-margin-25px-right sm-margin-15px-right">
                            <img src={iconImage} alt="" className="sm-w-60px sm-h-60px md-w-80px w-90px"/>
                        </div>
                        <div className="feature-box-content">
                            <h3 className="font-weight-700 text-extra-medium culture margin-10px-bottom text-pink text-uppercase sm-margin-5px-left">{props.title}</h3>
                            <p className="font-weight-500 text-black text-extra-medium culture sm-margin-5px-left">{props.text}</p>
                        </div>
                    </div>
            </Col>
        </Row>
    )
}

export default InfoBlockIcon;
