import React from 'react';

import {Container} from 'react-bootstrap';
import img from '../../../assets/images/decoration-elements/graf-cta.svg';
import play from '../../../assets/images/icons/play.svg';
import { Link } from "react-router-dom";


const CTA = props => {
    return (
        <section className="parallax padding-two-tb bg-green btn-shadow md-padding-3-rem-bottom md-padding-3-rem-top">
        <div className="opacity-medium bg-medium-slate-blue"></div>
            <Container className="flex-container-big-cta">
                    <div className="d-none d-lg-block"><img src={img} alt="" className="h-100 "/></div>
                    <div className="feature-box-hover md-no-margin md-margin-30px-left lg-margin-40px-left margin-50px-left">
                        <div className="feature-box-content feature-box h-100 padding-3-rem-all overflow-hidden lg-padding-2-half-rem-tb lg-padding-2-rem-lr sm-no-padding-lr">
                            <div className="process-step-item">
                                <div className="process-step-item-box">
                                    <div>
                                    <h4 className="font-weight-600 mb-0 text-purple title-large-3 text-uppercase">{props.claim}</h4>
                                    {props.text && 
                                        <div className="text-bg-cta text-purple"> 
                                            <span>{props.text}</span>
                                        </div>
                                    }
                                    </div>        
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to={{pathname:props.to, hash:'form'}} className="cta-btn d-flex align-items-center justify-content-sm-end">
                        <span className="process-step-number text-purple font-weight-800 bg-white">
                            <img src={play} alt="" className="play-cta"/>
                            <span className="process-step-number-bfr bg-white box-shadow-large"></span>
                        </span>
                    </Link>  
            </Container>
    </section>
    )
}

export default CTA;
