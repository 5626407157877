
//UPLOAD FILE TO BUCKET
export const GET_LINK_BUCKET = 'GET_LINK_BUCKET';
export const GET_LINK_BUCKET_SUCESS = 'GET_LINK_BUCKET_SUCESS';
export const GET_LINK_BUCKET_FAIL = 'GET_LINK_BUCKET_FAIL';

//FORMS
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCESS';
export const FORM_SUBMIT_FAIL = 'FORM_SUBMIT_FAIL';
export const RESET_SUBMIT = 'RESET_SUBMIT';

//WINDOW
export const UPDATING_WINDOW_WIDTH_HEIGHT = 'UPDATING_WINDOW_WIDTH_HEIGHT';
export const UPDATING_HEADER_HEIGHT = 'UPDATING_HEADER_HEIGHT';

//COOKIES
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const REMOVE_COOKIES = 'REMOVE_COOKIES';
export const SET_BANNER_VISIBILITY = 'SET_BANNER_VISIBILITY';