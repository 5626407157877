import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/functions';

const initialSatate = {
    windowWidth: null,
    windowHeight: null,
    headerHeight: null
}

const windowSize = (state = initialSatate, action) => {
    switch (action.type) {
        case actionTypes.UPDATING_WINDOW_WIDTH_HEIGHT:
            return updateObject( state, {windowWidth: action.width, windowHeight: action.height } );
        case actionTypes.UPDATING_HEADER_HEIGHT:
            return updateObject( state, { headerHeight : action.headerHeight});
        default:
            return state;
    }
};

export default windowSize;