import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import bottomElement from "../../assets/images/decoration-elements/background-vid-small.svg";
import Auxiliary from '../../hoc/Auxiliary';
import MainButton from '../../components/UI/MainButton/MainButton';

const PolicyContent= props =>{

    const content = props.content;
    const keys = Object.keys(content);
    
    const pageContent = keys.map((key) =>{
        const k = key.split('-');
        switch(k[0]){
            case 'text':
                return <p className="text-politics text-white margin-5px-bottom" key={props.key}>  
                    <Trans  
                    t={props.t}  
                    i18nKey={content[key]} 
                    components={{ 
                        linkHome: <Link to={props.t('routes:homepage.path')+'#top'} className="fw-600 green"/>,
                        linkMP: <a href="https://m-profiler.com/" target="_blank" rel="noreferrer" className="fw-600 green"></a>,
                        mailDPO: <a href="mailto:dpo@m-profiler.com" className="fw-600 green"></a>,  
                        linkSite : <a href="http://nearshorit.com/" target="_blank" rel="noreferrer" className="fw-600 green"></a>,
                        bold: <b></b>,
                        u: <u></u>
                    }}/>
               </p>
            case 'subtitle': 
                return <h2 className="font-weight-600 text-extra-large text-white d-block margin-5px-bottom margin-20px-top " key={props.key}>{content[key]}</h2>;
            case 'list': 
                const items = Object.values(content[key]);
                const displayItems= items.map((item) =>{
                        return (<li className="text-politics text-white margin-20px-left margin-0px-bottom">
                                    <Trans 
                                        t={props.t}  
                                        i18nKey={item}
                                        components={{ 
                                            bold: <b></b>
                                        }}/>
                                </li>)
                    }
                )
                return (<ul className="list-unstyled">{displayItems}</ul>);
            case 'btn': 
                return <MainButton click ={props.btnAction} key={props.key} color="green" btnText={content[key]} btnClasses={["fw-600","text-uppercase", "text-extra-medium"]} space="margin-30px-top"></MainButton>;
            default: return null;
        }
    });
    return(
        <Auxiliary>
            <section className="bg-purple no-padding-bottom position-relative politics-section">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={12} className="position-relative md-margin-30px-bottom">
                            <h1 className="font-weight-600 title-extra-small text-uppercase text-white d-block">{content["title"]}</h1>
                            {pageContent}
                        </Col>
                    </Row>
                </Container>
                <img src={bottomElement} className="background-policy d-none d-md-inline-block" alt=""/>
            </section>
            <section className="padding-two-tb bg-green md-padding-3-rem-bottom md-padding-3-rem-top position-relative">
                <div className="opacity-medium bg-medium-slate-blue"></div>
            </section>
            <section className="padding-two-tb bg-white md-padding-3-rem-bottom md-padding-3-rem-top d-none d-md-block">
                <div className="opacity-medium bg-medium-slate-blue position-relative"></div>
            </section>
        </Auxiliary>
    )
}

export default PolicyContent;
