import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import imgTitle from '../../../assets/images/decoration-elements/graf-location.svg';
import ImagesGallery from '../../UI/ImagesGallery/ImagesGallery';
import img1 from '../../../assets/images/gallery/1_home-image.jpg';
import img2 from '../../../assets/images/gallery/2_home-image.jpg';
import img3 from '../../../assets/images/homepage/services/purple-solid.svg';
import img4 from '../../../assets/images/gallery/4_home-image.jpg';
import img5 from '../../../assets/images/gallery/5_home-image.jpg';
import img6 from '../../../assets/images/homepage/services/green-solid.svg';
import img7 from '../../../assets/images/gallery/7_home-image.jpg';
import img8 from '../../../assets/images/gallery/8_home-image.jpg';

const images = [
    {
        img: img1,
        title: '',
        rows: 4,
        cols: 4,
    },
    {
        img: img2,
        title: '',
        cols: 4,
        rows: 2,
    },
    {
        img: img3,
        title: '',
        cols: 2,
        rows: 2,
    },
    {
        img: img4,
        title: '',
        cols: 2,
        rows: 2,
    },
    {
        img: img5,
        title: '',
        cols: 2,
        rows: 2,
    },
    {
        img: img6,
        title: '',
        cols: 2,
        rows: 2,
    },
    {
        img: img7,
        title: '',
        rows: 4,
        cols: 4,
    },
    {
        img: img8,
        title: '',
        cols: 4,
        rows: 2,
    },
    
];
const imagesMobile = [
    {
        img: img1,
        title: '',
        rows: 4,
        cols: 4,
    },
    {
        img: img2,
        title: '',
        cols: 4,
        rows: 2,
    },
    {
        img: img4,
        title: '',
        cols: 2,
        rows: 2,
    },
    {
        img: img5,
        title: '',
        cols: 2,
        rows: 2,
    },
    {
        img: img7,
        title: '',
        rows: 4,
        cols: 4,
    },
    {
        img: img8,
        title: '',
        cols: 4,
        rows: 2,
    },
    
];

const Location = props => {
    return (
        <section className="half-section sm-no-padding-bottom">
            <Container>
                <Row>
                    <Col xs={12}>
                        <ScrollAnimation animateIn="flipInX" animateOnce={true} duration={1.5}>
                            <div className="d-flex align-items-center">
                                <img src={imgTitle} alt="" className="d-none d-md-block margin-three-right md-w-150px lg-w-200px" />
                                <h2 className="font-weight-600 d-md-inline-block text-gradient-nearshorit title-large-2 no-margin-bottom xs-padding-15px-bottom text-uppercase">{props.t('homepage:4.title')}</h2>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
            {props.windowWidth < 992 ? <ImagesGallery bgWhite images ={imagesMobile}/> :<ImagesGallery images ={images}/>}
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(Location);



