import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import Auxiliary from '../../hoc/Auxiliary';
import Head from "../../components/partials/Head/Head";
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlock';
import Benefits from '../../components/Homepage/Benefits/Benefits';
import CTA from '../../components/UI/CTA/CTA';
import bgImage from '../../assets/images/banners/11_banner-big-data.jpg';

class BigData extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="bigData"/>    
                <Banner
                    title={this.props.t('bigData:1.title')}
                    icon="purple-solid.svg"
                    bgImage = {bgImage}
                />
                <InfoBlock
                    {...this.props}
                    title={this.props.t('bigData:2.title')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'bigData')['2']['services']}
                    transKey = 'bigData:2.services.'
                />
                <Benefits 
                    {...this.props} 
                    mainTitle = {this.props.t('bigData:3.title')}
                    boxesContent = {this.props.i18n.getResource(this.props.i18n.language, 'bigData')['3']['benefits']}
                    colorTitle = "green"
                />
                <CTA 
                    to = {this.props.t('routes:homepage.path')} 
                    btnText = {this.props.t('partials:cta.btnText')} 
                    claim = {this.props.t('bigData:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(BigData);