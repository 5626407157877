import React from 'react'
import { Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

const StackTechItem = props => {
    let image = require('../../../assets/images/stack/'+ props.image);

    return (
        <Col xs={6} md={4} lg={4}  className ="text-center md-margin-15px-bottom margin-30px-bottom md-padding-10px-lr sm-padding-5px-lr" > 
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={2} className="h-100">
                <div className={["feature-box", "feature-box-shadow", "box-shadow h-100", "padding-2-rem-lr", "padding-35px-tb", "sm-padding-15px-tb", "lg-padding-1-rem-lr", "sm-min-h-90px", "md-min-h-150px","min-h-200px", props.background ? "bg-white": "bg-medium-gray"].join(" ")}>
                    <div className="feature-box-icon">
                        <img src={image.default}  alt=""/>
                    </div>
                    {props.text && 
                        (<div className="feature-box-content last-paragraph-no-margin">
                            <h3 className="text-purple font-weight-600 d-block text-extra-medium text-uppercase margin-10px-tb stack">{props.text}</h3>
                        </div>)
                    }
                    <div className="feature-box-overlay bg-white border-radius-6px"></div>
                </div>
            </ScrollAnimation>
        </Col>
    )
}

export default StackTechItem
