import React from 'react';
import Auxiliary from '../../../hoc/Auxiliary';
import { Trans } from 'react-i18next';
import { Modal } from "react-bootstrap";
import Input from "../../UI/Input/Input";
import MainButton from '../../UI/MainButton/MainButton';

const FormModal = props => (
    <Auxiliary>
        <Modal show={props.modalOpen} onHide={props.closed} id={props.id} className="modalForm">
                <div className="btn-close" onClick={props.closed}></div>
                <h4 className="text-white title-extra-extra-small">{props.title}</h4>
                <p>
                    <Trans
                        t={props.t} 
                        i18nKey={props.textKey }
                        components={{
                            linkMail: <a href="mailto:letswork@nearshorit.com"></a>,
                            phone: <a href="tel:+351213304403"></a>
                        }} 
                    />
                </p>
                {props.btn && 
                    <Auxiliary>
                        <Input 
                            type="checkbox"
                            name="terms" 
                            id="terms"
                            label={props.checkbox.label} 
                            link={props.checkbox.link} 
                            onChange = {props.onChange}
                            checked={props.checked}/>
                            {props.checked !== false &&
                                <MainButton click={props.btn.onClick} btnText={props.btn.label} size="btn-medium" color="green"/>
                            }
                    </Auxiliary>
                }
                {props.btnClose && <MainButton click={props.closed} btnText={props.btnClose} size="btn-medium" color="green"/>}
        </Modal>
    </Auxiliary>
) ;

export default FormModal;