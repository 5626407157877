import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/partials/Head/Head';
import HomeBanner from '../../components/Homepage/HomeBanner/HomeBanner';
import About from '../../components/Homepage/About/About';
import Section3 from '../../components/Homepage/Section3/Section3';
import Map from '../../components/Homepage/Map/Map';
import Benefits from '../../components/Homepage/Benefits/Benefits';
import Location from '../../components/Homepage/Location/Location';
import Services from '../../components/UI/Services/Services';
import CTA from '../../components/UI/CTA/CTA';
import Mission from '../../components/Homepage/Mission/Mission';
import Contacts from '../../components/Homepage/Contacts/Contacts';

import missionImage from '../../assets/images/image-near-it.png';

import { isIE } from '../../shared/functions';
class Homepage extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="homepage"/>
                <HomeBanner {...this.props} />
                <About {...this.props} />
                <Section3 {...this.props} />
                <Benefits 
                    {...this.props} 
                    mainTitle = {this.props.t('homepage:3.title')}
                    boxesContent = {this.props.i18n.getResource(this.props.i18n.language, 'homepage')['3']['benefits']}
                    transKey="homepage:3.benefits."
                />
                {!isIE() && <Location {...this.props}/>}
                <Services 
                    {...this.props} 
                    services={this.props.i18n.getResource(this.props.i18n.language, 'homepage')['5']['services']} 
                    title={this.props.t('homepage:5.title')}
                />
                <CTA 
                    to = {this.props.t('routes:jobs.path')} 
                    btnText = {this.props.t('partials:cta.btnText')} 
                    claim = {this.props.t('homepage:cta.claim')}
                    text = {this.props.t('homepage:cta.text')}
                />
                <Mission 
                    img={missionImage} 
                    title = { this.props.t('homepage:6.title')}
                    text = { this.props.t('homepage:6.text')}
                />
                <Contacts {...this.props}/>
                <Map {...this.props}/>
            </Auxiliary>
        )
    }
}

export default withTranslation()(Homepage);