import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import Head from '../../components/partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import Banner from '../../components/UI/GeneralBanner/GeneralBanner';
import Services from '../../components/UI/Services/Services';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlock';
import CTA from '../../components/UI/CTA/CTA';
import bgImage from '../../assets/images/banners/2_banner-dev-software.jpg';

class SoftwareDevelopment extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="softwareDevelopment"/>
                <Banner
                    title = {this.props.t('softwareDevelopment:1.title')}
                    icon="purple-vertical-bars.svg"
                    bgImage = {bgImage}
                />
                <Services 
                    {...this.props} 
                    title = {this.props.t('softwareDevelopment:2.title')}
                    services = {this.props.i18n.getResource(this.props.i18n.language, 'softwareDevelopment')[2]['services']}  
                />
                <InfoBlock 
                    {...this.props}
                    title = {this.props.t('softwareDevelopment:3.title')}
                    infos = {this.props.i18n.getResource(this.props.i18n.language, 'softwareDevelopment')[3]['infos']}
                    transKey='softwareDevelopment:3.infos.'
                />
                <CTA 
                    to = {this.props.t('routes:homepage.path')} 
                    btnText = {this.props.t('partials:cta.btnText')} 
                    claim = {this.props.t('softwareDevelopment:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(SoftwareDevelopment);