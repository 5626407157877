export const updateWidthAndHeight = (width, height) => {
    return { 
        type: "UPDATING_WINDOW_WIDTH_HEIGHT", 
        width: width, 
        height: height
    }
};

export const updateHeaderHeight = (headerHeight) => {
    return { 
        type: "UPDATING_HEADER_HEIGHT", 
        headerHeight: headerHeight 
    }
};
