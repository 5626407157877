import { React, useRef, useEffect} from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'react-bootstrap';
import ContactForm from '../../UI/ContactForm/ContactForm';

const Contacts = props => {
    const formRef = useRef(null);
    let hash = props.location.hash;
    useEffect(() => {
        if(hash === "#form" && formRef.current.offsetTop){
            setTimeout(() => {
                window.scrollTo({
                    left:0, 
                    top:formRef.current.offsetTop,
                    behavior:"smooth"
                });
            }, 500);
            props.history.replace(props.location.pathname);
        }
    });


    return (
        <section ref={formRef} className="sm-padding-40px-tb">
            <Container>
                <Row>
                    <Col xs={12} className="margin-4-rem-bottom  sm-no-margin-top md-margin-25px-top md-margin-25px-bottom">
                        <ScrollAnimation animateIn="flipInX" animateOnce={true} duration={1.5}>
                            <Col>
                                <h2 className="font-weight-600 d-md-inline-block text-gradient-nearshorit title-large-2 no-margin-bottom">{props.t('homepage:7.title')}</h2>
                            </Col>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12}>
                        <ContactForm {...props}/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Contacts;
