import React, {useState}from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

//import element from '../../../assets/images/background-vid.svg';
import { Container, Row, Col } from 'react-bootstrap';
//import ModalVideo from 'react-modal-video';

const Future = props => {

    //const [isOpen, setOpen] = useState(false);

    return (
        <section className="bg-purple extra-big-section">
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="L61p2uyiMSo" onClose={() => setOpen(false)}/> */}
            <Container>
                <Row className="align-items-center justify-content-center">
                    <Col xs={12} className="position-relative md-no-margin-bottom margin-50px-bottom xl-margin-60px-bottom">
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={1.5}>
                            <img src={props.img} alt="" className="margin-30px-left"/>
                            <div className="absolute-left-middle-work-it">
                                <div className="move-to-bottom max-w">
                                    <h2 className="font-weight-600 title-large-2 green d-block xs-margin-15px-bottom">{props.title}</h2>
                                    <p className="text-large text-white line-height-28px">{props.text}</p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
            {/* <img src={element} className="background-vid d-none d-md-inline-block" alt=""/> */}
        </section>
    )
}

export default Future;
