import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const Block = props => {
    return (
        <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={2}>
            <div className=" margin-20px-tb feature-box text-left box-shadow-large box-shadow-double-large-hover bg-white padding-3-rem-lr padding-4-rem-top padding-2-rem-bottom">
                <div className="feature-box-content">
                    {props.title && 
                        <h3 className="text-purple font-weight-600 d-block text-extra-medium text-uppercase margin-10px-tb text-decoration-line-thick">
                            {props.title}
                        </h3>
                    }  
                    <p className={["text-extra-dark-gray", "font-weight-500", props.title ? null: "margin-15px-top text-decoration-line-thick"].join(" ")}>{props.text}</p>
                </div>
            </div>
        </ScrollAnimation>
    )
}

export default Block;


