import React from 'react';

import { Container, Row} from 'react-bootstrap';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { connect } from 'react-redux';


const srcset = (image, size, rows = 1, cols = 1) => {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const ImagesGallery = props => {
    
    const itemData = props.images;

    let imgsList = itemData.map((item) => {      
        if(props.windowWidth < 576){
            return(
                <ImageListItem key={item.img} cols={8|| 1} rows={4 || 1}>
                    <img
                        {...srcset(item.img, 121, 4, 8)}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            )
        } else if(props.windowWidth >= 576 && props.windowWidth <= 991){
            return(
                <ImageListItem key={item.img} cols={item.cols*2 || 1} rows={item.rows*2 || 1}>
                    <img
                        {...srcset(item.img, 121, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            )
        } else{
            return(
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img
                        {...srcset(item.img, 121, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            )
        }
    });

    return (
        <Container  className={["xs-no-margin","margin-40px-top", props.className].join(" ")}>
            <Row className="justify-content-center">
               {props.windowWidth < 576 ? <ImageList variant = "quilted" cols = {8} gap = {15} classes = {!props.bgWhite ? {quilted:"bg-purple"}: null}>
                    {imgsList}
                </ImageList>: 
                <ImageList variant = "quilted" cols = {8} gap = {15}>
                    {imgsList}
                </ImageList>}
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight,
        headerHeight: state.window.headerHeight
    }
}

export default connect(mapStateToProps)(ImagesGallery);
