import { React, useRef, useEffect, Suspense} from 'react';
import { Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../../hoc/Auxiliary';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuItem from '../../partials/Menu/MenuItem';
import Spinner from '../../UI/Spinner/Spinner';
import { Link } from "react-router-dom";

import logo from '../../../assets/images/logos/nearshorit-powered-by-MP.svg'

const Menu = props => {

    let menuClasses = ["hamburger-menu", "hamburger-menu-half", "bg-extra-dark-gray-opacity", "lg-w-60","md-w-75", "xs-w-100"].join(" ");

    const wrapperMenuRef = useRef(null);
    const closeMenuRef = useRef(null);

    //detect when clicked outside the menu area
    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', null)
        // cleanup this component
        return () => {
          window.removeEventListener('mousedown', handleClickOutside);
          window.removeEventListener('resize', null)
        };
    }, []);

    const handleClickOutside = (event) => {
        if (wrapperMenuRef.current.classList.contains("show-menu") && wrapperMenuRef && !wrapperMenuRef.current.contains(event.target)) {
           closeMenuRef.current.click();
        }
    }

    return(
        <Auxiliary>
            <Suspense fallback={<Spinner />}>
                <div className={[menuClasses, props.menuOpened ? "show-menu" :"", props.ie ? "ie" : "not-ie"].join(" ")} ref={wrapperMenuRef}>
                    <div onClick={props.closeMenu} className="close-menu text-white" ref={closeMenuRef}>
                        <i className="fas fa-times"></i>
                    </div>
                    <div className="d-flex flex-column align-items-flex-start justify-content-center h-90 sm-h-100 padding-2-rem-lr padding-5-rem-top padding-4-rem-bottom md-padding-2-rem-lr xs-no-padding-lr">
                        {/* <div className="w-70 xl-w-100 margin-auto-lr">
                            <Link to={props.t('routes:homepage.path')} className="d-inline-block xl-padding-55px-left padding-40px-left padding-60px-top ">
                                <img src={logo} alt="logo NearshorIT" className="md-w-160px" onClick={props.closeMenu}/>
                            </Link>
                        </div> */}
                        <Col xs={12} md={11} lg={11} className="menu-list-wrapper margin-30px-bottom margin-20px-top" data-scroll-options='{ "theme": "light" }'>
                            <Scrollbars 
                                renderThumbVertical={props => <div {...props} style={{backgroundColor: "rgba(0, 255, 187, 0.75)", width:"3px", borderRadius:"10px"}} />} 
                            >
                                <ul className="menu-list w-70 xl-w-100 margin-auto-lr md-padding-20px-left">
                                    <MenuItem route={props.t('routes:itConsulting.path')} text={props.t('routes:itConsulting.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:softwareDevelopment.path')} text={props.t('routes:softwareDevelopment.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:webDevelopment.path')} text={props.t('routes:webDevelopment.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:mobileDevelopment.path')} text={props.t('routes:mobileDevelopment.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:uxuiDesign.path')} text={props.t('routes:uxuiDesign.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:softwareTesting.path')} text={props.t('routes:softwareTesting.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:cloud.path')} text={props.t('routes:cloud.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:machineLearning.path')} text={props.t('routes:machineLearning.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:dataScience.path')} text={props.t('routes:dataScience.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:businessIntelligence.path')} text={props.t('routes:businessIntelligence.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:bigData.path')} text={props.t('routes:bigData.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:jobs.path')} text={props.t('routes:jobs.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:whyNearshorIT.path')} text={props.t('routes:whyNearshorIT.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:whyPortugal.path')} text={props.t('routes:whyPortugal.name')} click={props.closeMenu}/>  
                                    <MenuItem route={props.t('routes:faqs.path')} text={props.t('routes:faqs.name')} click={props.closeMenu}/>                
                                    <MenuItem route={props.t('routes:homepage.path') + "#contacts"} text={props.t('partials:contacts')} click={props.closeMenu}/>            
                                </ul>
                            </Scrollbars>
                        </Col>
                        <div className="w-70 xl-w-100 elements-social social-icon-style-12 d-none d-md-flex margin-auto-lr align-items-center">
                            <Link to={props.t('routes:homepage.path')} className="d-inline-block xl-padding-55px-left padding-40px-left md-padding-35px-left">
                                <img src={logo} alt="logo NearshorIT" className="w-250px" onClick={props.closeMenu}/>
                            </Link>
                            <div className="small-icon light">
                                <a className="linkedin text-center" href="https://www.linkedin.com/company/nearshorit/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            </div>
                            
                            {/* <ul className="small-icon light">
                                <li>
                                    <a className="linkedin text-center" href="https://www.linkedin.com/company/nearshorit/" target="_blank" rel="noreferrer">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </Suspense>
        </Auxiliary>
    )
}

export default withTranslation()(Menu);