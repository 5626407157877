import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import CustomAccordion from '../../components/UI/CustomAccordion/CustomAccordion';
import CTA from '../../components/UI/CTA/CTA';
import Auxiliary from '../../hoc/Auxiliary';
import { Row, Container, Col } from 'react-bootstrap';
import Head from '../../components/partials/Head/Head';
class Faqs extends Component {

    render() {
        return (
            <Auxiliary>
                <Head {...this.props} id="faqs"/>
                <section className="section bg-purple faqs-section">
                    <Container>
                        <Row >
                            <Col>
                                <h1 className="font-weight-700 green title-small margin-40px-bottom">FAQ's</h1>
                            </Col>
                        </Row>
                        <CustomAccordion 
                            faqs = {this.props.i18n.getResource(this.props.i18n.language, 'faqs')['faqs']}
                        />
                    </Container>
                </section>
                <CTA 
                    to={this.props.t('routes:homepage.path')} 
                    btnText={this.props.t('partials:cta.btnText')} 
                    claim={this.props.t('faqs:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Faqs);