import {React} from 'react';
import icon1 from '../../../assets/images/homepage/services/purple-vertical-bars.svg';
import { Container, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';

const GeneralBanner = props => {

    const iconTitle = props.icon ? require('../../../assets/images/homepage/services/'+ props.icon).default : icon1;
    
    const backgroundBanner = props.bgImage ?  {backgroundImage : `url(${props.bgImage})`, backgroundSize: props.windowWidth <= 575 ? "contain": "cover" , backgroundRepeat: "no-repeat"} : "";

    return (
        <section className="section bg-medium-light-gray general-banner position-relative" style={backgroundBanner}>
              <div className="bg-white half-padding">
                <Container className={["padding-30px-tb", props.text ? 'banner-text': 'banner-no-text'].join(" ")}>
                    <div className="row">
                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={1.5}>
                            <div>
                                <img src={iconTitle} alt="" className="margin-25px-right d-none d-lg-inline-block md-w-50px md-margin-15px-right"/>
                            </div>
                        </ScrollAnimation>
                        <Col xs={12} md={10} lg={9} xl={10} className="d-inline-block md-no-margin-top margin-20px-top">
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true} duration={1.5}>
                            <div className="d-flex align-items-center md-no-margin lg-margin-20px-bottom">
                                <img src={iconTitle} alt="" className="d-lg-none d-inline-block sm-w-40px md-w-50px margin-15px-right"/>
                                <h1 className="font-weight-600 d-md-inline-block text-purple title-small text-uppercase md-no-margin">{props.title} </h1>
                            </div>
                                {props.text &&   
                                    <div>
                                        <p className="text-extra-medium text-purple font-weight-500 md-margin-20px-top">{props.text}</p>
                                    </div>
                                }
                            </ScrollAnimation>
                        </Col>                          
                    </div>
                </Container>
            </div>
        </section>
    )
}
const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(GeneralBanner);


