import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import TitleCenter from '../TitleCenter/TitleCenter';
import Block from './Block';
import {Trans} from 'react-i18next';
import { Link } from 'react-router-dom';

import rightElement from '../../../assets/images/decoration-elements/element-infoblock.svg';


const InfoBlock = props => {
    const infos = props.infos;
    const transKey = props.transKey ? props.transKey : null;
    const infoKeys = Object.keys(infos);

    let content = null;
    let i = null;
    
    const max = parseInt(infoKeys.length / 3);
    const left = parseInt(infoKeys.length % 3);
    let displayInfo 
    let column = 1;
    let max1, max2, max3 = 0;

    if(left === 1){
        max1 = max + 1;
        max2 = max1 + max;
        max3 = max2 + max;
    } else if(left === 2){
        max1 = max + 1;
        max2 = max1 + max + 1;
        max3 = max2 + max;
    } else {
        max1 = max;
        max2 = 2 * max;
        max3 = 3 * max;
    }

    //build block indivisually
    const getBlock = (i) => {
        return (
            <Block 
                key={i}
                title={transKey ? 
                    <Trans 
                        t={props.t} 
                        i18nKey={props.transKey + i +".title"}  
                        components={{italic: <i></i> }}
                    /> : infos[i]['title']
                }  
                text={transKey ? 
                    <Trans 
                        t={props.t} 
                        i18nKey={props.transKey + i +".text"}  
                        components={{
                            italic: <i></i>,  
                            linkTests:<Link to={props.t('routes:softwareTests.path')} className="dark-purple"> </Link>,
                            linkMobile:<Link to={props.t('routes:mobileDevelopment.path')} className="dark-purple"> </Link>,
                            linkML:<Link to={props.t('routes:machineLearning.path')} className="dark-purple"> </Link>,
                            linkStudy: <a href="https://www.weforum.org/reports/the-global-competitiveness-report-2020" target="_new" className="dark-purple"/>,
                            linkStudy2: <a href="https://www.visionofhumanity.org/maps/" target="_new" className="dark-purple"/>
                        }}
                    /> : infos[i]['text']
                }
            />
        )
    }

    //display from 992px
    if(props.windowWidth >= 992){

        displayInfo = infoKeys.map (key => {
            //when should start a new column
            if( parseInt(key) === 1  || parseInt(key) === max1 +1  || parseInt(key) === max2 +1 ){
        
                content = [];
                i = parseInt(key);
               
                let total = column === 2 ? max2 : (column === 3 ? max3 : max1);
              
                //which blocks should be included in which columns
                while(i <= total){

                    let block = getBlock(i);
                    
                    content.push(
                        infos[i]['wrapLink'] ? (<Link to={props.t('routes:'+ infos[i]['wrapLink'])} key={i}>{block}</Link>): block
                    )
                    i += 1;
                }
              
                column += 1;
    
                return(
                        <div className="col md-no-margin margin-10px-tb">
                            {content}
                            {left !== 0 && column === 4 && (<img src={rightElement} className="rightElement" alt=""/>)}
                        </div>    
                )
            }
        })

    } else if(props.windowWidth < 992 && props.windowWidth >= 768){
        const leftTablet = infoKeys.length % 2;
        displayInfo = infoKeys.map (key => {
            //when shoul start a new column
            if( parseInt(key) === 1 || parseInt(key) === Math.ceil(infoKeys.length/2) +1 ){
                content = [];
                i = parseInt(key);
                
                let total = column === 1 ? Math.ceil(infoKeys.length/2): infoKeys.length;
                //which blocks should be included in which columns
                while(i <= total){
                    let block = getBlock(i);
                    content.push(
                        infos[i]['wrapLink'] ? (<Link to={props.t('routes:'+ infos[i]['wrapLink'])} key={i}>{block}</Link>): block
                    )
                    i += 1;
                }
              
                column += 1;
               
                return(
                        <div className="col md-no-margin margin-10px-tb">
                            {content}
                            {leftTablet !== 0 && column === 3 && (<img src={rightElement} className="rightElement" alt=""/>)}
                        </div>    
                )
            }
        })
    } else if(props.windowWidth < 768){
        displayInfo = infoKeys.map (key => {
            //when shoul start a new column
            if( parseInt(key) === 1 ){
                content = [];
                i = parseInt(key);
               
                let total = infoKeys.length;
                //which blocks should be included in which columns
                while(i <= total){
                    let block = getBlock(i);
                    content.push(
                        infos[i]['wrapLink'] ? (<Link to={props.t('routes:'+ infos[i]['wrapLink'])} key={i}>{block}</Link>): block
                    )
                    i += 1;
                }
              
                column += 1;
    
                return(
                    <div className="col md-no-margin margin-10px-tb">
                        {content}
                    </div>    
                )
            }
        })
    }
  
    
    return (
        <section className="sm-no-padding small-section bg-medium-gray xs-no-padding-top">
            <TitleCenter title={props.title} color="green"/>
            <Container>
                {props.windowWidth >= 992 && (<div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    {displayInfo}
                </div>)}
                {props.windowWidth >= 768 && props.windowWidth <= 991 &&  <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    {displayInfo}
                </div>}
                {props.windowWidth < 768 && <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    {displayInfo}
                </div>}
              
            </Container>
        </section>
      
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(InfoBlock);