import { React, Component } from "react";
import Auxiliary from '../hoc/Auxiliary';
import { BrowserRouter as Router } from "react-router-dom";
import { withRouter  } from 'react-router';
import { connect } from 'react-redux';
import { isIE, updateObject } from '../shared/functions';

//import components
import Header from "../components/partials/Header/Header";
import Footer from '../components/partials/Footer/Footer';
import CookiesBanner from "../components/partials/CookiesBanner/CookiesBanner";

//Check cookies value
import { getCookieConsentValue } from "react-cookie-consent";

//Google Tag Manager e Google Analytics
import * as ReactGA from "react-ga";

//actions
import { updateWidthAndHeight } from '../store/actions/window';
class Layout extends Component{
    constructor(props){
        super(props);
        this.state = {
            path: window.location, 
        }
    }
    
    componentDidMount(){
        this.props.history.listen((location, action) => {
            this.setPageViewGA(location);
        });
              
        //set initial window and haeder size
        this.updateWindowMeasures();

        //add event listener for window size
        window.addEventListener('resize', this.updateWindowMeasures);
        window.addEventListener('copy', this.copyText);
        
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowMeasures);
        window.removeEventListener('copy', this.copyText);
    }

    /*Set page view*/
    setPageViewGA(location){
        if ( getCookieConsentValue("cookiesAccepted") && typeof location.hash !== 'undefined' && (this.state.path.pathname !== location.pathname)){   
            ReactGA.pageview(window.location.pathname + window.location.search);
            this.setState(updateObject(this.state, {path: location }))
        }    
    } 

    /** Update window and headersize*/
    updateWindowMeasures = () => {
        this.props.onUpdateWidthAndHeight(window.innerWidth, window.innerHeight)
    };

    /** add text when content is copied from website */
    copyText = (event) => {
        let pageLink = ""
        
        pageLink = this.props.t('partials:copy.text') + ` ${document.location.href}`
        event.clipboardData.setData('text', document.getSelection() + pageLink);
        event.preventDefault();
    }

    render(){   
        return (
            <Auxiliary>
                <Router>
                    <Header 
                        {...this.props}
                        onChangeLanguage = {(lng)=>this.props.onChangeLanguage(lng)}   
                    />
                    <main className={["main", isIE() ? 'ie': null].join(" ")}>{this.props.children}</main>
                    <Footer {...this.props}/>
                    <CookiesBanner {...this.props} />
                </Router>
            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.windowWidth,
        windowHeight: state.windowHeight,
        headerHeight: state.headerHeight
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateWidthAndHeight : (width, height) => dispatch(updateWidthAndHeight(width, height))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
