import {React, useState, useRef, useEffect, Suspense} from "react";
import { Col, Row, Container, Form} from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { elementsToJson } from '../../../shared/functions';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/forms';

import Spinner from '../../UI/Spinner/Spinner';
import Auxiliary from "../../../hoc/Auxiliary";
import Input from "../Input/Input";
import FormModal from "../../partials/FormModal/FormModal";
import MainButton from '../../UI/MainButton/MainButton';
import CountrySelector from "../../UI/SelectCountry/SelectCountry";


const ContactForm = (props) =>{

    //controll checkbox state
    const [checked, setChecked] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [openedOnce, setOpenedOnce] = useState(false);
    const [clearSelect, setClearSelect] = useState(false);
    const refBtnSubmit = useRef();
    const refForm = useRef();
    const submited = props.submited;

    //modal content
    const [ modalContent , setModalContent  ] = useState({
        title: null,
        textKey: null,
        btn: null,
        checkbox: null,
    });

    const checkForm = (event) => {
        //check the form validity
        event.preventDefault();
        if(event.target.form.checkValidity()){
            //cehck if terms are accepted
            if(checked){
                submitForm(event);
            }
            else{
                if(!openedOnce){
                    setModalContent({
                        title: props.t('modals:termsMissing.title') ,
                        textKey: props.t('modals:termsMissing.text'),
                        btn: {
                            label: props.t('modals:termsMissing.btn'),
                            onClick : (e) => refBtnSubmit.current.click()
                        },
                        checkbox: {
                            link: props.t('routes:privacyPolicy.path'),
                            label: props.t('modals:termsMissing.terms')
                        }
                    })
                    setModalOpen(true);
                    setOpenedOnce(true);
                }
                else{
                    //open modall saying it didn't send the contact
                    setModalContent({
                        title: props.t('modals:errorTermsMissing.title') ,
                        textKey: props.t('modals:errorTermsMissing.text'),
                    })
                    setModalOpen(true);
                }
            }
        } else{
            let scroll = true;
            const _elements = Object.entries(event.target.form.elements);
            _elements.forEach((obj) =>{
                if (!isNaN(parseInt(obj[0]))){
                    //create event
                    const event = new Event('input',{
                        bubbles: true,
                        cancelable: true,
                    });
                    //dispatch event to the input with error
                    obj[1].dispatchEvent(event);
                    if ( scroll && obj[0] !== "0" ){
                        scroll = false;
                        obj[1].scrollIntoView({
                            behavior: 'smooth', 
                            block: 'nearest', 
                            inline: 'nearest' 
                        });
                    }
                }
            })
        }
    }

    //submit form after checking inputs and terms
    const submitForm = (event) => {
        event.preventDefault();
        //bot control
        if ( event.target.form.elements.honey && event.target.form.elements.honey.value !== '' ){
            setModalContent({ 
                title: props.t('modals:errorSend.title'), 
                textKey: props.t('modals:errorSend.text') 
            });
            setModalOpen(true);
            props.onResetSubmit();
            return;
        }

        const inputValues =  elementsToJson(event.target.form.elements);          
        refBtnSubmit.current.disabled =  true;
        refBtnSubmit.current.classList.add('loading');
        
        //send email
        props.onTrySubmitContactForm('contacts', inputValues);
    }

    //open success on fail modal after submiting post
    useEffect(() => {    
        if (submited){
            setModalContent({
                title: props.t('modals:sucessSend.title'),
                textKey: props.t('modals:sucessSend.text'),
            });   
            setModalOpen(true);
            refBtnSubmit.current.classList.remove('loading');
            refBtnSubmit.current.disabled =  false;
            props.onResetSubmit();

            //clear inputs
            refForm.current.reset();
            setChecked(false);
            setClearSelect(true);
        }else if( submited === false ) {
            setModalContent({ 
                title: props.t('modals:errorSend.title'), 
                textKey: props.t('modals:errorSend.text') 
            });
            refBtnSubmit.current.classList.remove('loading');    
            refBtnSubmit.current.disabled =  false;
            setModalOpen(true);
            props.onResetSubmit();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submited]);

    return(
        <Auxiliary>
            <FormModal 
                {...props}
                id='contactsModal' 
                title={modalContent.title} 
                textKey={modalContent.textKey} 
                btn={modalContent.btn}  
                checkbox = {modalContent.checkbox}  
                checked={checked}  
                modalOpen = {modalOpen}
                onChange={(e)=> setChecked(e.target.checked)} 
                closed={() => setModalOpen(false)}/>
            <Container>
                <Suspense fallback={<Spinner />}>
                    <Form autoComplete="off" className="costumForm" id="form1" action="POST" ref={refForm}>
                        <input type="text" name="honey" id="honey" className="d-none" autoComplete="off" />
                        <Row className="row-cols-1 row-cols-md-2">
                            <Col className="margin-15px-bottom">
                                <Form.Group className="mb-3">
                                    <Input 
                                        type="text" 
                                        placeholder={props.t('forms:placeholders.name')} 
                                        name="name" 
                                        minLength={3} 
                                        label={props.t('forms:labels.name')}
                                        errormsg={props.t('forms:messages.errorMinLength')} 
                                        requiredmsg={props.t('forms:messages.errorRequired')} 
                                        required
                                    />
                                    <Input 
                                        type="text" 
                                        placeholder={props.t('forms:placeholders.company')}  
                                        name="company" 
                                        minLength={3} 
                                        label= {props.t('forms:labels.company')}  
                                        errormsg={props.t('forms:messages.errorMinLength')} 
                                    />
                                    <CountrySelector  clear={clearSelect} label= {props.t('forms:labels.country')} {...props}/>                
                                </Form.Group>
                            </Col>
                            <Col className="md-no-margin-bottom  margin-15px-bottom">
                                <Form.Group>
                                    <Input 
                                        type="email" 
                                        placeholder={props.t('forms:placeholders.email')} 
                                        name="email" 
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
                                        label={props.t('forms:labels.email')} 
                                        errormsg={props.t('forms:messages.errorEmail')}  
                                        requiredmsg={props.t('forms:messages.errorRequired')} 
                                        required
                                    />
                                    <Input 
                                        type="textarea"
                                        name="message" 
                                        placeholder={props.t('forms:placeholders.message')} 
                                        minLength={10} 
                                        label={props.t('forms:labels.message')}  
                                        errormsg={props.t('forms:messages.errorMinLength')}  
                                        requiredmsg={props.t('forms:messages.errorRequired')} 
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row >
                            <Col className="d-flex flex-column align-items-end justify-content-end" >
                                <Input 
                                type="checkbox"
                                name="terms" 
                                id="terms"
                                checked = {checked}
                                label={props.t('forms:labels.terms')} 
                                link={props.t('routes:privacyPolicy.path')} 
                                onChange={(e)=> setChecked(e.target.checked)} 
                                />
                                <MainButton 
                                    _ref={refBtnSubmit} 
                                    btnText={props.t('forms:labels.btn')} 
                                    click={(e) => checkForm(e)} 
                                    color="green" 
                                    space="margin-1-rem-top"
                                />
                            </Col>
                        </Row>
                    </Form>
                </Suspense>
            </Container>
        </Auxiliary>
    )
}

const mapStateProps = state => {
    return {
        submited: state.forms.submited,
    }
}

const mapDispatchProps = dispatch => {
    return {
        onTrySubmitContactForm: (typeForm, info) => dispatch( actions.submitForm(typeForm, info)),
        onResetSubmit: () => dispatch( actions.resetSubmit() ),
    }
}

export default connect(mapStateProps, mapDispatchProps)(withTranslation()(ContactForm));