import React from 'react';

//Animations
import ScrollAnimation from 'react-animate-on-scroll';
import Tilt from 'react-parallax-tilt';

import { Col, Container, Row } from 'react-bootstrap';
import img1 from '../../../assets/images/homepage/about/elemento_1.svg';
import img20 from '../../../assets/images/homepage/about/elemento_2.svg';
import { isIE } from '../../../shared/functions';

const About = props => {

   // const isIE = isIE();

    return (
        <section className="home-freelancer-bg-img">
            <Container>
                <img src={img1} alt="" className="backgound-about d-none d-md-inline-block"/>
                <Row className="align-items-center justify-content-center z-index-0 position-relative">
                    <Col xs={12} md={10} lg={4} className="text-center text-lg-left last-paragraph-no-margin md-margin-30px-bottom xs-no-margin-bottom xs-margin-30px-top md-padding-10px-lr lg-padding-50px-left xl-padding-50px-left">
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={2}>
                            <h1 className= "font-weight-600 text-purple text-large text-uppercase d-block margin-20px-bottom">{props.t('homepage:2.title')}</h1>
                            <p className="text-large line-height-38px text-purple">{props.t('homepage:2.text')}</p>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} sm={7} md={8} lg={5} >
                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2}> 
                            {isIE() ? (<div><img src={img20} alt="" className=""/></div>) : (<Tilt perspective = "1000" transitionEasing= "cubic-bezier(.03,.98,.52,.99)" scale="1" transitionSpeed="500" reset="true" glareMaxOpacity="1" className="text-center text-sm-left text-md-right pl-sm-0 tilt-box lg-padding-4-rem-top lg-padding-3-rem-bottom md-padding-3-rem-top md-padding-3-rem-bottom home-freelancer-bg-img sm-no-padding-tb">
                                <img src={img20} alt="" className="img-20-years"/>
                                {/* <span className="title-large-2 font-weight-600 text-uppercase text-purple">+</span>
                                <span className="text-big font-weight-600 text-uppercase text-purple letter-spacing-minus-10px md-letter-spacing-minus-5px xs-letter-spacing-minus-1-half">20</span> */}
                            </Tilt>)}
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} sm={5} md={4} lg={3} className="text-center text-sm-left">
                        <ScrollAnimation animateIn="fadeInRight" animateOnce={true} duration={2}> 
                            <h5 className="font-weight-300 text-purple text-uppercase w-90 mb-0 margin-negative-top-left xs-no-margin-left sm-w-100">{props.t('homepage:2.claim1')} <span className="font-weight-700">{props.t('homepage:2.claim2')}</span></h5>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}


export default About;