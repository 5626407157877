import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Head from '../../../components/partials/Head/Head';
import Auxiliary from '../../../hoc/Auxiliary';
import PolicyContent from '../PolicyContent';

class Privacy extends Component {
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="privacyPolicy"/>
                <PolicyContent {...this.props} content={this.props.i18n.getResource(this.props.i18n.language, 'privacyPolicy')}/>
            </Auxiliary>
        )
    }
}

export default withTranslation()(Privacy);