import * as actionsTypes from "./actionsTypes";

export const acceptCookies = () => {
    return { 
        type: actionsTypes.ACCEPT_COOKIES, 
    }
};

export const removeCookies = () => {
    return { 
        type: actionsTypes.REMOVE_COOKIES, 
    }
};

export const setBannerVisibility = (value) => {
    return { 
        type: actionsTypes.SET_BANNER_VISIBILITY, 
        value
    }
};



