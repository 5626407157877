import { takeEvery, all } from 'redux-saga/effects';
import * as actionsTypes from "../actions/actionsTypes";

import {
    submitForm,
    getLinkBucket
} from './forms';

export function* watchForm(){
    yield all([
        takeEvery(actionsTypes.GET_LINK_BUCKET, getLinkBucket)
    ]);
    yield all([
        takeEvery(actionsTypes.SUBMIT_FORM, submitForm)
    ]);
}
