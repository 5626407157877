//check if should change language
export const shouldChangeLanguage = (i18n) => {
    let routes = getRoutesByLocale(i18n);
    let routeKeys = Object.values(routes);
    routeKeys.forEach((key) =>{
        if (routes.key && routes.key.path === window.location.pathname){
            return false;
        }
    })
    return true;
}

// return all routes by locale 
export const getRoutesByLocale = (i18n, lang) => {
    let language = lang ? lang : i18n.language;
    const routes = i18n.getResource(language,'routes'); 
    return routes;
}

//get the key of the current path
export const currentRouteKey = (i18n) => {
    let allRoutes = getRoutesByLocale(i18n);
    const currentPathname = window.location.pathname;
    //const currentRouteKey = Object.keys(allRoutes).find((key) => allRoutes[key].path === currentPathname);
    let routeKey = false;
    
    for ( let key in allRoutes ){ 

        let pathTemp = currentPathname;
        if ( pathTemp.substring(pathTemp.length - 1) === '/' && pathTemp.length > 1){
            pathTemp = pathTemp.substring(0, pathTemp.length - 1);
        }

        // let pathTempCurrent = allRoutes[key].path;
        // if ( pathTempCurrent.substring(pathTempCurrent.length-1) === '/' && pathTempCurrent.length > 1 ){
        //     pathTempCurrent = pathTempCurrent.substring(0, pathTempCurrent.length - 1);
        // }
        if ( pathTemp.split('/').length > 2 ){
            pathTemp = "/" + pathTemp.split('/')[1] + "/:id";
        }
        if( allRoutes[key].path === pathTemp ){
            routeKey = key;
        }
    }

    return routeKey;
}

//get the path in new lang
export const getPathNewLang = (i18n, lng, key) => {
    return i18n.t(`routes:${key}.path`, { lng: lng });
}

//get lang that corresponds to current path
export const getLanguageByRoute = (route, i18n) => {
    let possibleLanguages = i18n.languages;
    let routesByLang = null;
    let response = false;
    if ( route.substring(route.length - 1) === '/' && route.length > 1)
        route = route.substring(0, route.length - 1);
    
    if ( route.split('/').length > 2 )
        route = "/" + route.split('/')[1] + "/:id";
    
    possibleLanguages.forEach( (lang) =>{
        routesByLang = getRoutesByLocale(i18n, lang);
        if (Object.values(routesByLang).find((routeLang) => routeLang.path === route))
            response = lang;
    });

    return response;
}

//remove duplicated form elements
export const removeDuplicatesElements = elms => {
    let _elements = {...elms};
    Object.keys(_elements).forEach((key) =>  {
        if (!isNaN( parseInt(key)))
            delete _elements[key];
        } 
    );
    if ( !Object.keys(_elements).length){
        elms.forEach(e => {
            _elements[e.name] = e;
        });
    }
    return _elements;
}

//transform form elements to JSON
export const elementsToJson = elms => {
    const _elms = removeDuplicatesElements(elms);
    let json = {};
    Object.keys(_elms).forEach((key) => {
       json[key] = key === 'terms' ? 'sim' : _elms[key].value;
    });
    return json;
}

//update object function
export const updateObject = (oldObject, updatedProperties) => {
    return{
        ...oldObject,
        ...updatedProperties
    }
}
//add ... when number of chars is bigger than max
export const maxChar = ( text , max ) =>{
    return text.substr(0, max) + ' (...)' ;
}

//check if we are on IE
export const isIE = () => (navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode);