import {React} from 'react';
import Auxiliary from '../../../hoc/Auxiliary';
import bgImage from '../../../assets/images/homepage/image-home.jpg';
import mouse from '../../../assets/images/icons/icon-rato.svg'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';


const HomeBanner = props => {

    //define banner height
    let styleFullScreen = props.windowWidth <= 991 ? {'height' : (props.windowHeight - props.headerHeight)} : {'height' : (props.windowHeight)};
    
    return(
        <Auxiliary>
            <section className="cover-background p-0 overflow-visible" style={{backgroundImage : `url(${bgImage})`}}>
                <Container>
                    <Row className="full-screen md-h-650px sm-h-550px xs-h-450px" style={styleFullScreen}>
                        <Col xs={12} sm={7} className="d-flex flex-column md-justify-content-end padding-5-rem-tb">
                            <h1 className="font-weight-600 title-large-4 green mt-auto mb-0 " style={{"whiteSpace": "pre-line"}}>
                               { props.t('homepage:1.title1')}
                                <span className="text-border-neon green text-border-width-2px title-large-4 ">{ props.t('homepage:1.title2')}</span><br />
                                {props.t('homepage:1.title3')}
                            </h1>
                           <div className="media font-weight-500 text-extra-medium text-extra-dark-gray mt-auto"></div>
                        </Col>
                        <Col xs={12} className="position-absolute left-0px bottom-0px">    
                            <div className="absolute-middle-center d-flex flex-column justify-content-center text-center w-80px h-80px mx-auto section-link sm-w-70px sm-h-70px rounded-circle bg-white box-shadow-medium">
                                <img src={mouse} className="sm-w-40px sm-h-40px w-50px h-50px margin-auto"/>
                                {/* <i className="ti-mouse icon-extra-medium bounce text-extra-dark-gray"></i> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Auxiliary>
    );
 };

 const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight,
        headerHeight: state.window.headerHeight
    }
}

export default connect(mapStateToProps)(HomeBanner);