import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/partials/Head/Head';
import BannerWhy from '../../components/UI/BannerWhy/BannerWhy';
import ImagesGallery from '../../components/UI/ImagesGallery/ImagesGallery';

import CTA from '../../components/UI/CTA/CTA';
import Culture from '../../components/UI/Culture/Culture';

import img1 from '../../assets/images/gallery/whyNearshorit/1_nearshorit-image.jpg';
import img2 from '../../assets/images/gallery/5_home-image.jpg';
import img3 from '../../assets/images/gallery/whyNearshorit/2_nearshorit-image.jpg';
import img4 from '../../assets/images/gallery/whyNearshorit/3_nearshorit-image.jpg';

import { isIE } from '../../shared/functions';
import { connect } from 'react-redux';

const images = [
    {
        img: img1,
        title: '',
        cols: 2,
        rows: 2,
    },
    {
        img: img2,
        title: '',
        cols: 2,
        rows: 2,
    },    
    {
        img: img3,
        title: '',
        rows: 4,
        cols: 4,
    },
    {
        img: img4,
        title: '',
        cols: 4,
        rows: 2,
    }
];
const imagesMobile = [
    {
        img: img1,
        title: '',
        cols: 2,
        rows: 2,
    }, 
    {
        img: img2,
        title: '',
        rows: 2,
        cols: 2,
    },
    {
        img: img3,
        title: '',
        rows: 4,
        cols: 4,
    },
    {
        img: img4,
        title: '',
        cols: 4,
        rows: 2,
    }
];
class WhyNearshorit extends Component{
    render(){
        return(
            <Auxiliary>
                <Head {...this.props} id="whyNearshorIT"/>
                <BannerWhy title={this.props.t('whyNearshorit:1.title')} text={this.props.t('whyNearshorit:1.text')}/>
                {!isIE() && this.props.windowWidth < 576 ? <ImagesGallery {...this.props}  images ={imagesMobile} className="bg-purple"/> :<ImagesGallery {...this.props} images ={images} className="margin-100px-bottom md-no-margin-tb"/>}
                <Culture 
                    {...this.props} 
                    title={this.props.t('whyNearshorit:2.title')} 
                    content={this.props.i18n.getResource(this.props.i18n.language, 'whyNearshorit')['2']['advantages']}
                    transKey = "whyNearshorit:2.advantages."
                />
                <CTA 
                    to = {this.props.t('routes:homepage.path')} 
                    btnText = {this.props.t('partials:cta.btnText')} 
                    claim = {this.props.t('whyNearshorit:cta.claim')}
                />
            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(withTranslation()(WhyNearshorit));