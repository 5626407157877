import { put } from 'redux-saga/effects';
import * as actions from '../actions/forms';
import { instance_API_AWS } from '../../axios';
import axios from 'axios';

export function* getLinkBucket(action) {
    try{  
        const fileName = action.file.name;
        // try get link to upload file
        const responseAws = yield instance_API_AWS.post('/upload',{
            fileName : fileName
        });

        //get response results
        const fields = yield responseAws.data.fields;              
        const url = yield responseAws.data.url;

        // formating data in formData
        const formData = yield new FormData();

        yield Object.keys(fields).forEach(key => {
            formData.append(key,  fields[key]);
        });
        yield formData.append("file", action.file);
      
        // try upload file
        const bucket = yield axios.post(url, formData);
       
        if ( bucket.status === 204 ){
            //try get link to download file
            yield put(actions.setSuccessLinkbucket(fields.key));
        }else {
            yield put( actions.setFailLinkbucket());
        } 
    }catch(error){
       
        yield put( actions.setFailLinkbucket());
    }
}

export function* submitForm(action) {
    try{    
        const data = yield {
            "channel" : "NearshorIT",
            "toAddress" : "joao.branco@m-profiler.com",
            [action.typeForm] : [action.info][0]
        };
        
        yield instance_API_AWS.post('/send-email', data);
        yield put( actions.sucessSubmitForm());
    }catch(error){
        yield put( actions.failSubmitForm());
    }
}