import React from 'react';
import { Container, Row, Image, Col } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

//images
import logo from '../../../assets/images/logos/nearshorit-powered-by-MP.svg';

//get year to display on footer
const year = new Date().getFullYear();

const Footer = props => {

    const linkHereNow = props.i18n.language !== 'pt' ? '//www.hereandnow.agency/en' : '//www.hereandnow.agency/' ;
    const linkMatchProfiler = props.i18n.language !== 'pt' ? '//www.m-profiler.com/en' : '//www.m-profiler.com/' ;

    return(
         <footer className="footer-dark">
            <div className="footer-top sm-padding-30px-top padding-50px-top padding-30px-bottom bg-purple">
                <Container>
                    <Row className="sm-margin-20px-bottom padding-one-tb">
                        <Col xs={8} md={12}>
                            <Image src={logo} data-at2x={logo} className="default-logo" alt=""/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} lg={3} className="sm-no-padding-tb md-no-padding-bottom padding-one-tb">
                            <ul>
                                <li><NavLink to={props.t('routes:itConsulting.path')} className="text-extra-medium font-weight-700">{props.t('routes:itConsulting.name')}</NavLink></li>
                                <li><NavLink to={props.t('routes:webDevelopment.path')} className="text-extra-medium font-weight-700">{props.t('routes:webDevelopment.name')}</NavLink></li>
                                <li><NavLink to={props.t('routes:softwareDevelopment.path')} className="text-extra-medium font-weight-700">{props.t('routes:softwareDevelopment.name')}</NavLink></li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={6} lg={3} className="sm-no-padding-tb md-no-padding-bottom padding-one-tb">
                            <ul>                           
                                <li><NavLink to={props.t('routes:mobileDevelopment.path')} className="text-extra-medium font-weight-700">{props.t('routes:mobileDevelopment.name')}</NavLink></li>
                                <li><NavLink to={props.t('routes:businessIntelligence.path')} className="text-extra-medium font-weight-700">{props.t('routes:businessIntelligence.name')}</NavLink></li>
                                <li><NavLink to={props.t('routes:uxuiDesign.path')} className="text-extra-medium font-weight-700">{props.t('routes:uxuiDesign.name')}</NavLink></li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={6} lg={3} className="md-no-padding-tb padding-one-tb">
                            <ul>                           
                                <li><NavLink to={props.t('routes:softwareTesting.path')} className="text-extra-medium font-weight-700">{props.t('routes:softwareTesting.name')}</NavLink></li>
                                <li><NavLink to={props.t('routes:cloud.path')} className="text-extra-medium font-weight-700">{props.t('routes:cloud.name')}</NavLink></li>
                                <li><NavLink to={props.t('routes:dataScience.path')} className="text-extra-medium font-weight-700">{props.t('routes:dataScience.name')}</NavLink></li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={6} lg={3} className="md-no-padding-tb padding-one-tb">
                            <ul>
                                <li><NavLink to={props.t('routes:jobs.path')} className="text-extra-medium green font-weight-700">{props.t('routes:jobs.name')}</NavLink></li>                           
                                <li><NavLink to={props.t('routes:faqs.path')} className="text-extra-medium green font-weight-700">{props.t('routes:faqs.name')}</NavLink></li>                       
                                <li><NavLink to={props.t('routes:homepage.path') + "#contacts"} className="text-extra-medium green font-weight-700">{props.t('partials:contacts')}</NavLink></li>                       
                            </ul>
                            {/* <div className="elements-social social-icon-style-12 sm-padding-2-rem-top padding-4-rem-top">
                                <ul className="large-icon light text-right">
                                    <li>
                                        <a className="linkedin text-center" href="https://www.linkedin.com/company/nearshorit/" target="_blank">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-top padding-one-tb bg-gradient-nearshore-small"></div>
            <div className="footer-bottom padding-one-tb bg-purple">
                <Container> 
                    <Row>
                        <Col xs={12} lg={6} className="text-left text-white order-2 order-lg-1"> 
                            <p className="text-extra-small text-shadow-1"> Copyright &copy; {year} <span>&#x25cf;</span> <Link target="_new" to={linkMatchProfiler}>Match Profiler Lda</Link>{props.t('partials:copyright.name')} <Link target="_new" to={linkHereNow}>Here &amp; Now Agency</Link></p>
                        </Col>
                        <Col xs={12} lg={6} className="text-lg-right order-1 order-lg-2"> 
                            <p className="d-flex">
                                <NavLink to={props.t('routes:privacyPolicy.path')} className="font-weight-700 text-extra-medium margin-four-right text-uppercase sm-margin-10px-bottom text-shadow-1">{props.t('routes:privacyPolicy.name')}</NavLink>
                                <NavLink to={props.t('routes:cookiesPolicy.path')} className="font-weight-700 text-extra-medium margin-four-right text-uppercase  d-block-mobile text-shadow-1">{props.t('routes:cookiesPolicy.name')}</NavLink>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}

export default Footer;