import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Container, Col, Row } from 'react-bootstrap';
import Step from '../../UI/Steps/Step';

const Steps = props => {
    let steps = props.steps;
    let stepKeys = Object.keys(steps);
    const totalSteps = stepKeys.length.toString();
    let displaySteps = stepKeys.map(key => {
        return (<Step title={steps[key]['title']} key={key} step={key} last = {stepKeys.length === parseInt(key) ? true : null} text={steps[key]['text'] ? steps[key]['text']: null}/> )
    })

    return (
        <section className="bg-white steps-section">
            <div className="bg-purple">
                <Container>
                    <Col xs={12} className="text-md-center">
                        <ScrollAnimation animateIn="flipInX" animateOnce={true} duration={1.5}>
                            <h2 className="font-weight-600 d-md-inline-block green title-small sm-margin-15px-bottom">{props.title} </h2>
                        </ScrollAnimation>
                    </Col>  
                    <Row className={["row-cols-1","row-cols-md-"+totalSteps,"justify-content-center", "margin-35px-tb", "sm-no-margin-tb"].join(" ")}>
                        {displaySteps}
                    </Row>         
                </Container>
            </div>
        </section>
    )
}

export default Steps;
