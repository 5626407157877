import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const BenefitInfo = props => {

    let source = props.img ? require("../../../assets/images/icons" + props.img) : null;

    return (
        <div className="col margin-20px-tb md-no-margin-bottom">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={2} className="h-100">
                <div className="bg-white box-shadow-medium last-paragraph-no-margin h-100">
                    {props.img && 
                        <div className={!props.text ? "d-flex d-md-block align-items-center": null}>
                            {props.text ? <div className="bg-purple d-flex justify-content-center lg-h-150px h-180px align-items-center">
                                <img src={source.default} alt="" className="img-fluid lg-h-75px h-90px"/>
                            </div> : <div className="bg-purple d-flex justify-content-center lg-h-150px h-180px align-items-center md-h-110px sm-h-100px sm-min-w-80px">
                                <img src={source.default} alt="" className="img-fluid lg-h-75px h-90px md-w-70px md-h-70px sm-w-50px sm-h-50px"/>
                            </div>}
                            <div className="position-relative padding-4-rem-top sm-padding-20px-top padding-20px-bottom padding-25px-lr">
                                {props.text ? <span className="w-4px h-70px bg-green absolute-left-middle-center top-0px sm-h-30px"></span>: <span className="w-4px h-70px bg-green absolute-left-middle-center top-0px sm-h-30px d-none d-md-block"></span>}
                                <div className={["text-purple", "font-weight-700", "text-uppercase", props.text ? "margin-10px-bottom": null].join(" ")}>{props.title}</div>
                                {props.text && <p className="text-extra-dark-gray font-weight-500 text-extra-medium">{props.text}</p>}
                            </div>
                        </div>    
                    }

                    {!props.img && 
                        <div>
                            <div className="bg-purple h-65px"></div>
                            <div className="position-relative padding-4-rem-top padding-20px-bottom padding-25px-lr">
                                <span className="w-4px h-70px bg-green absolute-left-middle-center top-0px sm-h-30px"></span>
                                <div className={["text-purple", "font-weight-700", "text-uppercase", props.text ? "margin-10px-bottom": null].join(" ")}>{props.title}</div>
                                {props.text && <p className="text-extra-dark-gray font-weight-500 text-extra-medium">{props.text}</p>}
                            </div>
                        </div>}
                </div>
            </ScrollAnimation>
        </div>
    )
}

export default BenefitInfo;
