import {React, Component, Suspense} from "react";
import i18n from './i18n';
import {
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";

//import functions
import { currentRouteKey, getPathNewLang, shouldChangeLanguage, getLanguageByRoute} from './shared/functions'
import { withTranslation } from "react-i18next";
import { isIE, updateObject } from './shared/functions';

//import containers
import Homepage from './containers/Homepage/Homepage';
import Cloud from './containers/Cloud/Cloud';
import BusinessIntelligence from './containers/BusinessIntelligence/BusinessIntelligence';
import SoftwareTesting from './containers/SoftwareTesting/SoftwareTesting';
import DataScience from './containers/DataScience/DataScience';
import ITConsulting from './containers/ITConsulting/ITConsulting';
import WebDevelopment from './containers/WebDevelopment/WebDevelopment';
import MobileDevelopment from './containers/MobileDevelopment/MobileDevelopment';
import SoftwareDevelopment from './containers/SoftwareDevelopment/SoftwareDevelopment';
import UXUIDesign from './containers/UXUIDesign/UXUIDesign';
import MachineLearning from "./containers/MachineLearning/MachineLearning";
import BigData from "./containers/BigData/BigData";
import WhyNearshorit from "./containers/WhyNearshorit/WhyNearshorit";
import WhyPortugal from "./containers/WhyPortugal/WhyPortugal";
import Jobs from "./containers/Jobs/Jobs";
import Faqs from "./containers/Faqs/Faqs";
import PrivacyPolicy from './containers/Policy/Privacy/Privacy';
import CookiesPolicy from './containers/Policy/Cookies/Cookies';
import Page404 from './containers/Page404/Page404';
import Layout from "./hoc/Layout";

//import components
import FormModal from "./components/partials/FormModal/FormModal";
import Spinner from "./components/UI/Spinner/Spinner"

//import style
import './App.scss';
import ScrollToTop from "./hoc/ScrollToTop";
class App extends Component {      
    constructor(props) {
        super(props);
        this.state = {
            routes: { 
                'en': i18n.getResource('en','routes'),
                'pt': i18n.getResource('pt','routes'),
                'de': i18n.getResource('de','routes'),
            },
            language : null,
            redirectComponent: null,
            modalContent:{
                id: null,
                title: null,
                textKey: null,
                btnClose:null
            },
            modalOpen: false,
        }
    }
    componentDidMount(){
        //check if should change lang by verifying the route
        if(shouldChangeLanguage(i18n)){
            const routeLanguage = getLanguageByRoute(window.location.pathname,i18n);
            if(routeLanguage){
                i18n.changeLanguage(routeLanguage);
                this.setState(
                    {...this.state, redirectComponent:<Redirect to={window.location.pathname}/>, language: routeLanguage},
                    () => this.setState({...this.state, redirectComponent:null})
                );
            }
        }
        if(isIE()){
            this.setState(
                updateObject(
                    this.state,
                    {   
                        modalContent: {
                            id:"ieModal",
                            title : this.props.t('modals:ie.title'),
                            textKey : 'modals:ie.txt-1',
                            btnClose: this.props.t('modals:ie.btn')
                        },
                        isIe: true,
                        modalOpen: true,
                    }
                )
            )
        }  
    }

    render(){
        //changeLanguage function
        const changeLanguage = (lng) => {
            //get the key of the current path
            let currentKey = currentRouteKey(i18n);
            if(!currentKey) {
                currentKey = 'homepage';
            }
            //change language 
            i18n.changeLanguage(lng);
            //get the path in new lang
            let newPath = getPathNewLang(i18n, lng, currentKey);
            this.setState(
                {...this.state, redirectComponent:<Redirect to={newPath}/>, language: lng},
                () => this.setState({...this.state, redirectComponent:null})
            );
        }  
        
        return (
            <Layout {...this.props} onChangeLanguage = {(lng)=>changeLanguage(lng)}>  
             <FormModal 
                    {...this.props}
                    id={this.state.modalContent.id} 
                    title={this.state.modalContent.title} 
                    textKey={this.state.modalContent.textKey} 
                    btnClose={this.state.modalContent.btnClose}
                    modalOpen = {this.state.modalOpen}
                    closed={() => this.setState(updateObject(this.state, {modalOpen: false}))}
                /> 
                <div>
                    {this.state.redirectComponent}
                    <ScrollToTop>
                        <Switch>
                            <Route path={[
                                this.state.routes.pt.businessIntelligence.path, 
                                this.state.routes.en.businessIntelligence.path, 
                                this.state.routes.de.businessIntelligence.path
                            ]} component={BusinessIntelligence} exact />
                            <Route path={[
                                this.state.routes.pt.cloud.path, 
                                this.state.routes.en.cloud.path, 
                                this.state.routes.de.cloud.path
                            ]} component={Cloud} exact />
                            <Route path={[
                                this.state.routes.pt.dataScience.path, 
                                this.state.routes.en.dataScience.path, 
                                this.state.routes.de.dataScience.path
                            ]} component={DataScience} exact />
                            <Route path={[
                                this.state.routes.pt.itConsulting.path, 
                                this.state.routes.en.itConsulting.path, 
                                this.state.routes.de.itConsulting.path
                            ]} component={ITConsulting} exact />
                            <Route path={[
                                this.state.routes.pt.mobileDevelopment.path, 
                                this.state.routes.en.mobileDevelopment.path, 
                                this.state.routes.de.mobileDevelopment.path
                            ]} component={MobileDevelopment} exact />
                            <Route path={[
                                this.state.routes.pt.softwareDevelopment.path, 
                                this.state.routes.en.softwareDevelopment.path, 
                                this.state.routes.de.softwareDevelopment.path
                            ]} component={SoftwareDevelopment} exact />
                            <Route path={[
                                this.state.routes.pt.softwareTesting.path,
                                this.state.routes.en.softwareTesting.path,
                                this.state.routes.de.softwareTesting.path
                            ]} component={SoftwareTesting} exact />
                            <Route path={[
                                this.state.routes.pt.uxuiDesign.path,
                                this.state.routes.en.uxuiDesign.path,
                                this.state.routes.de.uxuiDesign.path
                            ]} component={UXUIDesign} exact />
                            <Route path={[
                                this.state.routes.pt.webDevelopment.path,
                                this.state.routes.en.webDevelopment.path,
                                this.state.routes.de.webDevelopment.path
                            ]} component={WebDevelopment} exact />
                            <Route path={[
                                this.state.routes.pt.bigData.path,
                                this.state.routes.en.bigData.path,
                                this.state.routes.de.bigData.path
                            ]} component={BigData} exact />
                            <Route path={[
                                this.state.routes.pt.machineLearning.path,
                                this.state.routes.en.machineLearning.path,
                                this.state.routes.de.machineLearning.path
                            ]} component={MachineLearning} exact />
                            <Route path={[
                                this.state.routes.pt.jobs.path,
                                this.state.routes.en.jobs.path,
                                this.state.routes.de.jobs.path
                            ]} component={Jobs} exact />
                            <Route path={[
                                this.state.routes.pt.faqs.path,
                                this.state.routes.en.faqs.path,
                                this.state.routes.de.faqs.path
                            ]} component={Faqs} exact />
                            <Route path={[
                                this.state.routes.pt.whyNearshorIT.path,
                                this.state.routes.en.whyNearshorIT.path,
                                this.state.routes.de.whyNearshorIT.path
                            ]} component={WhyNearshorit} exact />
                            <Route path={[
                                this.state.routes.pt.whyPortugal.path,
                                this.state.routes.en.whyPortugal.path,
                                this.state.routes.de.whyPortugal.path
                            ]} component={WhyPortugal} exact />
                            <Route path={[
                                this.state.routes.pt.privacyPolicy.path,
                                this.state.routes.en.privacyPolicy.path,
                                this.state.routes.de.privacyPolicy.path
                            ]}component={PrivacyPolicy} exact />
                            <Route path={[
                                this.state.routes.pt.cookiesPolicy.path,
                                this.state.routes.en.cookiesPolicy.path,
                                this.state.routes.de.cookiesPolicy.path
                            ]}
                            render= { () => (<CookiesPolicy/>)} 
                            exact />
                            <Route path={[
                                this.state.routes.pt.homepage.path,
                                this.state.routes.en.homepage.path,
                                this.state.routes.de.homepage.path
                            ]}component={Homepage} exact w={this.props.windowWidth}/>
                            {/* <Route path="*" component={Page404}/> */}
                            <Route render={() => <Suspense fallback={<Spinner />}><Page404 /></Suspense>} /> 
                        </Switch>
                    </ScrollToTop>
                </div>
                {/*Force to include DE routes, check if needed in the future */}
                <Link to={'/de'} className="d-none" />
            </Layout>
        );
    }
}

export default withTranslation()(App)
