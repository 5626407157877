import {React, useState} from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

const AccordionItem = props =>{
    const [expanded, setExpanded] = useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === 'panel'+ props.key} onChange={handleChange('panel'+ props.key)} className="border-radius-0-px accordion-item-faqs">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography sx={{ flexShrink: 0, color:"#B33DFF", fontWeight: '700'}} className={["text-extra-medium", "padding-65px-left", "md-padding-35px-left", "sm-padding-20px-left","md-padding-10px-right", "padding-25px-right","padding-25px-top", "sm-padding-10px-top", "w-100", expanded === 'panel'+ props.key ? "padding-0px-bottom":"sm-padding-15px-bottom padding-25px-bottom"].join(" ")}>
                    {props.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography sx={{ fontWeight: '500'}} className="text-extra-medium text-extra-dark-gray padding-65px-left md-padding-35px-left sm-padding-20px-left padding-25px-right md-padding-10px-right padding-25px-bottom">
                   {props.text}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default AccordionItem;
