import React from 'react';

import Auxiliary from '../../../hoc/Auxiliary';

const LanguageChanger = props => {
   
    return(
        <Auxiliary>
               <div className={["d-flex","flex-column languages", props.languageOpened ? "active": null].join(" ")} onMouseLeave={props.out}>
                    <button onClick={() => props.onChangeLanguage('pt')} className={["margin-auto-lr", props.currentLang === 'pt' ? 'active': null].join(" ")}>PT</button>
                    <button onClick={() => props.onChangeLanguage('en')} className={["margin-auto-lr", props.currentLang === 'en' ? 'active': null].join(" ")}>EN</button> 
                    <button onClick={() => props.onChangeLanguage('de')} className={["margin-auto-lr", props.currentLang === 'de' ? 'active': null].join(" ")}>DE</button>
                </div>
        </Auxiliary>
    )
}

export default LanguageChanger;